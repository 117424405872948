import React, { Component } from 'react';
import BasicView from '../../../components/Layouts/BasicView/BasicView';
import { contentData } from './content';
import { withStyles} from '@material-ui/core';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import PatientHistoryForm from './components/PatientHistoryForm/PatientHistoryForm';
import { request_patient_history, request_update_patient_history} from './requests';


class EditPatientHistory extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    patient_history:null,
    modals:{
      password_modal:{open:false,issending:false,error:null},
      image_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language, catalogs} = this.props
    const {patient_history, isloading, issending, error, modals} = this.state

    const content = contentData[language]



    return(
      <BasicView
        isloading={isloading}
        content={content.layout}
        onReturn={this.onReturn}
      >
        <div>
          <CardWrapper>
            <div className={classes.root}>
              <PatientHistoryForm 
                patient_history={patient_history}
                language={language} 
                catalogs={catalogs} 
                isloading={issending}
                error={error}
                onSubmit={this.onSubmit}
                onCancel={this.onReturn}/>
            </div>
          </CardWrapper>
        </div>

      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      let response = await request_patient_history(id)
      this.setState({patient_history:{...response}})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onSubmit = async(data) => {
    const {id} = this.props.match.params
    if(data){
      if(JSON.stringify(data) !== "{}"){
        this.setState({issending:true})
        try {
          let response = await request_update_patient_history(id,data)
          this.setState({patient_history:{...response}})
          this.setState({error:null, issending:null})
          this.props.history.goBack()
        } catch (error) {
          if(error.response){
            this.setState({issending:false})
            let e = {...error.response.data.error}
            this.setState({error:`#${e.code}, ${e.message}`})
          }else{
            this.setState({error:`No Internet`})
          }
          
        }
        
      }
    }
  }


  onReturn = () => {
    this.props.history.goBack()
  }

}

const styles = theme => ({
  root:{
    padding:32
  },
  link:{
    cursor:'pointer'
  }
})

export default withStyles(styles)(EditPatientHistory)
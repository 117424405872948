import {mainServer} from '../../../../variables/config'

export const request_adult_vaccination = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/adult`)
  return response.data.data.adult_vaccination_found
}

export const request_update_adult_vaccination = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/vaccination/${id}/adult`, data)
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/adult`)
  return response.data.data.adult_vaccination_found
}
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Detalles de consultas',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Consulta',path:'/patients/:id/appointments/:appointment_id'},
      ],
    },
    motive_modal:{
      title:'Motivo',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    diagnosis_modal:{
      title:'Diagnóstico',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    recipe_details_modal:{
      title:'Detalles',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    recipe_indications_modal:{
      title:'Indicaciones adicionales',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    add_medication_modal:{
      title:'Agregar medicamento',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    edit_medication_modal:{
      title:'Editar medicamento',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    delete_medication_modal:{
      title:'¿Estás seguro?',
      message:'¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido',
      cancelButton:'Cancelar',
      deleteButton:'Eliminar'
    },

    table:{
      nodata:'-',
      //add_button:{
      //  icon:'add',
      //  label:'Agregar'
      //},
      header:[
        {id:1, label:'Paciente', flex:1},
        {id:2, label:'Tipo', flex:1},
        {id:3, label:'Fecha', flex:1},
        {id:4, label:'Inicio', flex:1},
        {id:5, label:'Fin', flex:1},
      ],
      filter_list:[
        {id:1, label:'Todo', type:null},
        {id:2, label:'Próximas', type:null},
        {id:3, label:'Fecha', type:'date'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'data',
          keys:['patient_name'],
        },
        {
          type:'data',
          keys:['appointment_type'],
        },
        {
          type:'data',
          keys:['appointment_date'],
          icon:{
            name:'calendar_today'
          }
        },
        {
          type:'data',
          keys:['start_hour'],
          icon:{
            name:'access_time'
          }
        },
        {
          type:'data',
          keys:['end_hour'],
          icon:{
            name:'access_time'
          }
        },
      ]
    },
    
  }
}
import React, { Component } from 'react';
import { withStyles, Dialog, Grid } from '@material-ui/core';
import { SecondRoundedButton } from '../Buttons/GeneralButtons';
import Subtitle from '../Texts/Subtitle';
import Caption from '../Texts/Caption';
import { palette } from '../../variables/config';
import SimpleLoader from '../Loaders/SimpleLoader';
import { red } from '@material-ui/core/colors';

class BasicModal extends Component {
  render(){

    const {classes,onClose,open, isloading, error, onSubmit, content, maxWidth, width} = this.props

    let infoContent = <div className={classes.empty}></div>
    if(isloading){
      infoContent = <Grid container justify='center'>
        <Grid item>
          <SimpleLoader />
        </Grid>
      </Grid>
    }else{
      if(error){
        infoContent = <Grid container justify='center'>
        <Grid item>
          <Caption text={error} medium color={red[700]}/>
        </Grid>
      </Grid>
      }
    }

    return(
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        classes={{paper:classes.paper}}
      >
        <div className={classes.root} style={{width:width}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Subtitle text={content ? content.title : ''} color={palette.primary.main} medium/>
            </Grid>
            <Grid item xs={12}>
              {this.props.children}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {infoContent}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='flex-end' spacing={1}>
                    <Grid item>
                      <SecondRoundedButton label={content ? content.cancel_button : ''} color='grey' size={'small'} onClick={onClose}/>
                    </Grid>
                    <Grid item>
                      <SecondRoundedButton label={content ? content.submit_button : ''} color='primary' size={'small'} onClick={onSubmit}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </div>
      </Dialog>
        
    )
  }
}

const styles = theme => ({
  root:{
    padding:16,
    boxSizing:'border-box',
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
  },
  empty:{
    height:30
  },
  paper:{
    borderRadius:40,
    padding:12,
    paddingRight:8,
    paddingLeft:8
  },
})

export default withStyles(styles)(BasicModal)
export const contentData = {
  spanish:{
    form:{
      title:{
        label:'Título',
        placeholder:'Título',
        helper:'Título no válido'
      },
      description:{
        label:'Descripción',
        placeholder:'Descripción (opcional)',
        helper:'Descripción no válida'
      },
    }
  }
}
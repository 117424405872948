export const formData = {
  recipe_details:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'recipe_details',
      type:'text',
      multiline:true,
      rows:5,
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}
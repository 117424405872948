import {mainServer} from '../../variables/config'

export const request_reauth = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/login/reauthenticate")
  let current_data = JSON.parse(localStorage.getItem('data'))
  current_data = {...current_data, ...response.data.data}
  localStorage.setItem('data',JSON.stringify(current_data))
}

export const request_me = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/users/me")
  return response.data.data.user_found
}
export const contentData = {
  spanish:{
    menu_form:[
      {id:0, label:'General'},
      {id:1, label:'Adicional'},
    ],
    form:{
      current_conditions:{
        label:'Condiciones actuales',
        placeholder:'Condiciones actuales',
        helper:'Información no válida'
      },
      pathological_history:{
        label:'Antecedentes personales patológicos',
        placeholder:'Antecedentes personales patológicos',
        helper:'Información no válida'
      },
      family_history:{
        label:'Antecedentes heredofamiliares',
        placeholder:'Antecedentes heredofamiliares',
        helper:'Información no válida'
      },
      tobacco_condition_id:{
        label:'Tabaco',
        placeholder:'Tabaco',
        helper:'Debe de seleccionar una opción'
      },
      alcohol_condition_id:{
        label:'Alcohol',
        placeholder:'Alcohol',
        helper:'Debe de seleccionar una opción'
      },
      home_type_id:{
        label:'Vivienda',
        placeholder:'Vivienda',
        helper:'Debe de seleccionar una opción'
      },
      home_services:{
        label:'Servicios',
        placeholder:'Servicios',
        helper:'Información no válida'
      },
      home_pets:{
        label:'Faunas',
        placeholder:'Faunas',
        helper:'Información no válida'
      },
      inmunization_card:{
        label:'Inmunizaciones',
        placeholder:'Inmunizaciones',
        helper:'Información no válida'
      },
      
    }
  }


  
}
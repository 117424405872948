export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Editar perfil',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Editar',path:'/patients/:id/edit'},
      ]
    },
    password_modal:{
      title:'Cambio de contraseña',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    }
    
  }
}
import {mainServer} from '../../../variables/config'
import axios from 'axios'
import moment from 'moment'

export const request_add_patient = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/users", data)
  let patient_id = response.data.data.user_id
  const data2send = {
    patient_id:patient_id,
    related_date: moment().format('YYYY-MM-DD HH:mm:ss')
  }
  await mainServer.instance.post('/doctorpatient/doctor/me',data2send)
  return patient_id
}

export const request_doctor_translations = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/me/translations/1")
  return response.data.data.doctor_details_translation
}

export const request_update_me = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/users/me",data)
  let response
  response = await mainServer.instance.get("/users/me")
  return response.data.data.user_found
}

export const request_update_bio = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/doctor/me/translations/1",data)
}

export const request_delete_image = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/users/me/image'
  await mainServer.instance.delete(url)
  let response
  response = await mainServer.instance.get("/users/me")
  return response.data.data.user_found
}

export const request_upload_image = async(file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = '/users/me/image'
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.upload_url, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/users/me")
  return response.data.data.user_found
}
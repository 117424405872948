import moment from 'moment'

export const cleanDocumentData = ( data ) => {

  let documents = []

  if(data){
    documents = data.map(item => {
      let status = item.document && (item.document.length > 0) && item.document != "0"
      return {
        id:item.id_patient_document, 
        type:0,
        status: status,
        path: status ? item.document.split('/')[2] : null,
        data:{
          title: item.title,
          description: item.description,
          date: item ? moment(item.created_at).format('DD/MM/YYYY') : '',
        }
      }
    })
  }
  
  return documents
}

export const cleanImageData = ( data ) => {

  let images = []

  if(data){
    images = data.map(item => {
      let status = item.document && (item.document.length > 0) && item.document != "0"
      return {
        id:item.id_patient_image, 
        type:1,
        status: status,
        path: status ? item.image.split('/')[2] : null,
        data:{
          title: item.title,
          description: item.description,
          date: item ? moment(item.created_at).format('DD/MM/YYYY') : '',
        }
      }
    })
  }
  
  return images
}
export const formData = {
  first_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'first_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  fathers_last_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'fathers_last_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  mothers_last_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'mothers_last_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  user_gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'user_gender_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'email',
      type:'email',
      fullWidth: true,
    },
    rules:{
      type:'email',
    }
  },
  mobile:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'mobile',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  blood_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'blood_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  birth_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'birth_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'date',
    }
  },
  curp:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'curp',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  fixed_phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'fixed_phone',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  timezone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'timezone',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  bio:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'bio',
      type:'text',
      multiline:true,
      rows:8,
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:0,
      max:1024
    }
  },
}
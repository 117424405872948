import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import VaccinationTable from '../../../../components/Tables/VaccinationTable';
import { request_infant_vaccination, request_update_infant_vaccination } from './request';
import moment from 'moment'
import EditVaccinationModal from './modals/EditVaccinationModal/EditVaccinationModal';


class InfantVaccinationView extends Component {

  state = {
    isloading:false,
    infant_vaccination:null,
    vaccination_selected:null,
    modals:{
      vacinnation_modal:{open:false,issending:false,error:null},
    },
  }
  
  render(){

    const {classes,language, catalogs} = this.props
    const {isloading, infant_vaccination, modals, vaccination_selected} = this.state
    const content = contentData[language]

    let data_vaccination = JSON.parse(JSON.stringify(content.infant_vacinnation))
    content.infant_vacinnation.body.forEach((item,key) => {
      item.data.forEach((el,id)=>{
        if(infant_vaccination){
          let current_date = moment(infant_vaccination[el.id])
          if(current_date.isValid()){
            data_vaccination.body[key].data[id].data = current_date.format('DD/MM/YYYY')
          }else{
            data_vaccination.body[key].data[id].data = '-'
          }
        }
      })
    })

    let modalContent = null

    if(modals.vacinnation_modal.open){
      modalContent = (
        <EditVaccinationModal
          origin_data={vaccination_selected}
          language={language}
          content={content.vacinnation_modal}
          data={modals.vacinnation_modal}
          onClose={() => this.onCloseModal('vacinnation_modal')}
          onSubmit={this.onUpdateVaccination}
        />
      )
    }

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
        onReturn={this.onReturn}
      >
        {modalContent}
        <div className={classes.root}>
          <CardWrapper nopadding>
            <div className={classes.root}>
              <VaccinationTable data={data_vaccination} onEdit={this.onChangeVaccination}/>
            </div>
          </CardWrapper>
        </div>
      </BasicView>
    )
  }

  onChangeVaccination = (item) => {
    this.setState({vaccination_selected:item})
    this.onOpenModal('vacinnation_modal')
  }

  onUpdateVaccination = async(item) => {
    const {id} = this.props.match.params
    const type = 'vacinnation_modal'
    this.onEditModal(type,{issending:true})
    
    try {
      let response = await request_update_infant_vaccination(id,item)
      this.setState({infant_vaccination:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  componentDidMount(){
    this.initModule()
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      //const {id} = this.props.match.params
      let response = await request_infant_vaccination(id)
      this.setState({infant_vaccination:{...response}})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
  }
})

export default withStyles(styles)(InfantVaccinationView)
import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import {
  Grid,
  Typography,
  Dialog,
  withWidth,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import SimpleLoader from '../../../../../../../components/Loaders/SimpleLoader'
import { ErrorMessage} from '../../../../../../../components/DisplayData/DisplayData'
import SimpleDropzone from '../../../../../../../components/Dropzones/SimpleDropzone'
import  {RoundedButton} from '../../../../../../../components/Buttons/GeneralButtons'
import Subtitle from '../../../../../../../components/Texts/Subtitle.jsx';
import { palette } from '../../../../../../../variables/config.jsx';
import { contentData } from './content.jsx';
import { formData } from './data.jsx';
import ShadowInputSelect from '../../../../../../../components/Inputs/ShadowInputSelect/ShadowInputSelect.jsx';
import ShadowInputText from '../../../../../../../components/Inputs/ShadowInputText/ShadowInputText.jsx';
import ContentText from '../../../../../../../components/Texts/ContentText.jsx';


class ImageModal extends Component {

  state = {
    current_file: [],
    msg_file: '',
    isValid: false,
    formData: JSON.parse(JSON.stringify(formData))
  }

  onDropFile = (files) => {
    const msg = files[0].name
    console.log(files)
    this.setState({current_file: [...files],msg_file: msg,isValid: true})
  }

  onUpdateFile = () => {
    const {data, onSubmit} = this.props
    const {current_file} = this.state
    onSubmit(data.data,current_file)
  }

  render(){
    const {msg_file, formData} = this.state
    const {classes,open, data, onClose, language} = this.props
    const content = contentData[language]
    const {issending, error} = data

    let loaderContent = null;
    if(issending){
      loaderContent = <div><SimpleLoader/></div>
    }
    let errorContent = null;
    if(error){
      errorContent = <ErrorMessage message={error}/>
    }

    let contentFilename = null
    if(msg_file){
      contentFilename = (
        <Grid container spacing={1}>
          <Grid item>
            <ContentText text={content.subtitle} medium variant='light'/>
          </Grid>
          <Grid item xs>
            <ContentText text={msg_file} medium />
          </Grid>
        </Grid>
      )
    }

    let formContent = null
    formContent = Object.keys(formData).map((item,key)=>{
      let inputContent = null
      switch (formData[item].config.type) {
        case 'select':
          inputContent = (
            <ShadowInputSelect 
              data={formData[item]}
              onChange={this.onInputChange}/>
          )
          break;
        
        default:
          inputContent = (
            <ShadowInputText data={formData[item]} onChange={this.onInputChange}/>
          )
          break;
      }
      return(
        <Grid item xs={12} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
        classes={{paper:classes.paper}}
      >
        <DialogTitle>
          <Subtitle text={data.data.name} medium color={palette.primary.main}/>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {formContent}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <ContentText text={content.form.filename.label} medium variant='light'/>
                </Grid>
                <Grid item xs={12}>
                  <SimpleDropzone language={language} onDrop={this.onDropFile}/>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12}>
              {contentFilename}
            </Grid>
            <Grid item xs={12}>
              {errorContent}
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {loaderContent}
          <RoundedButton
            label={content.cancel}
            size='small'
            color='grey'
            onClick={onClose}
          />
          <RoundedButton
            label={content.button}
            size='small'
            color='secondary'
            onClick={this.onSubmit}
          />
        </DialogActions>
      </Dialog>
    )
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData, current_file} = this.state;
    let temp = {...formData}

    let isFormGood = true
    Object.keys(formData).forEach(item => {
      if(temp[item].isRequired){
        if(!temp[item].isValid){
          isFormGood = false
          temp[item].isVisited = true
        }
      }
    })

    if(isFormGood && current_file){
      let data2Send = {};
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      const ext = current_file[0].type.split('/')
      data2Send = {...data2Send, type:ext[1]}
      onSubmit(data2Send, current_file)
    }else{
      this.setState({formData:{...temp}})
    }
  }

  componentDidMount(){
    const { language} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}

    Object.keys(formData).forEach((item) => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    this.setState({formData:{...temp}})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(withWidth()(ImageModal));

export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Pacientes',
      navigation:[
        {name:'Pacientes',path:'/patients'},
      ],
    },
    table:{
      nodata:'-',
      header:[
        {id:1, label:'Doctor', flex:1},
        {id:2, label:'Motivo', flex:1},
        {id:3, label:'Fecha'},
      ],
      filter_list:[
        {id:1, label:'Todo'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'avatar',
          image_key:'image',
          flex:1,
          keys:['doctor_name'],
        },
        {
          type:'data',
          keys:['note'],
          flex:1,
        },
        {
          type:'data',
          keys:['timestamp'],
          icon:{
            name:'calendar_today'
          }
        },
        
        
      ]
    },
    
  }
}
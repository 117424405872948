export const formData = {
  note:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'note',
      type:'text',
      multiline:true,
      rows:3,
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}
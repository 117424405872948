import React from 'react'
import TableDragSelect from 'react-table-drag-select'

import "./schedule_styles.css"

const WeekScheduler = props => {

    const {cells, timeslots, onChange, disabled} = props

    let temp = [...cells]
    let tableContent = []
    temp.forEach((item,key)=>{
      if(key !== cells.length-1){
        tableContent.push(
          <tr key={key.toString()}>
            <td disabled>{timeslots[key]}</td>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
            <td disabled={disabled}/>
          </tr>
        )
      }
      
    })


  return(
    <div >
      
      <TableDragSelect value={JSON.parse(JSON.stringify(temp))} onChange={onChange}>
      <tr >
        <td disabled style={{borderTopLeftRadius:8}}/>
        <td disabled>Lunes</td>
        <td disabled>Martes</td>
        <td disabled>Miercoles</td>
        <td disabled>Jueves</td>
        <td disabled>Viernes</td>
        <td disabled>Sábado</td>
        <td disabled style={{borderTopRightRadius:8}}>Domingo</td>
      </tr>
        {tableContent}
      </TableDragSelect>
    </div>
  )

  
}

export default WeekScheduler
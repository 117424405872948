import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import { request_edit_medication, request_delete_medication, request_add_medication, request_edit_appointment, request_recipe_medications, request_recipe, request_diagnosis, request_appointment, request_doctor, request_doctor_details, request_edit_diagnosis, request_edit_recipe } from './requests';
import AppointmentCard from './components/AppointmentCard';
import { Grid } from '@material-ui/core';
import EvaluationCard from './components/EvaluationCard';
import DiagnosisCard from './components/DiagnosisCard';
import RecipeCard from './components/RecipeCard';
import EditMotiveModal from './modals/EditMotiveModal/EditMotiveModal';
import EditDiagnosisModal from './modals/EditDiagnosisModal/EditDiagnosisModal';
import EditRecipeDetailsModal from './modals/EditRecipeDetailsModal/EditRecipeDetailsModal';
import EditRecipeIndicationsModal from './modals/EditRecipeIndicationsModal/EditRecipeIndicationsModal';
import AddMedicationModal from './modals/AddMedicationModal/AddMedicationModal';
import WarningModal from '../../../components/Modals/WarningModal';
import EditMedicationModal from './modals/EditMedicationModal/EditMedicationModal';
import MainDiagnosisCard from './components/MainDiagnosisCard';

const diagnosis_title={
  "diagnosis_1":"Diagnóstico principal",
	"diagnosis_2":"Diagnóstico secundario",
	"diagnosis_3":"Diagnóstico terciario",
	"diagnosis_4":"Diagnóstico cuaternario",
	"diagnosis_5":"D5",
	"head_exploration":"Cabeza y cuello",
	"torax_exploration":"Torax",
	"abdomen_exploration":"Abdomen",
	"inferior_limbs_exploration":"Miembros inferiores",
	"superior_limbs_exploration":"Miembros superiores",
	"genitalia_exploration":"Genitales"
}

class AppointmentView extends Component {

  state = {
    isloading:false,
    users:[],
    appointments:[],
    appointment:null,
    doctor_details:null,
    diagnosis:null,
    recipe:null,
    medications:[],
    modals:{
      motive_modal:{open:false,issending:false,error:null},
      diagnosis_modal:{open:false,issending:false,error:null},
      recipe_details_modal:{open:false,issending:false,error:null},
      recipe_indications_modal:{open:false,issending:false,error:null},
      add_medication_modal:{open:false,issending:false,error:null},
      edit_medication_modal:{open:false,issending:false,error:null},
      delete_medication_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language, translations, catalogs} = this.props
    const {selected_diagnosis, modals, isloading, appointment, doctor_details, diagnosis,medications, selected_medication, recipe} = this.state
    const content = contentData[language]

    let filter_medications = this.onUpdateMedications(medications)
    //console.log(filter_medications)
    let modalcontent = null
    if(modals.motive_modal.open){
      modalcontent = (
        <EditMotiveModal
          origin_data={appointment}
          data={modals.motive_modal}
          content={content.motive_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('motive_modal')}
          onSubmit={this.onEditAppointment}
        />
      )
    }

    let diagnosis_content = {...content.diagnosis_modal}
    let diagnosis_data = {diagnosis:''}

    if(selected_diagnosis){
      console.log(selected_diagnosis)
      diagnosis_content = {
        ...diagnosis_content,
        title:diagnosis_title[selected_diagnosis]
      }
      diagnosis_data = {diagnosis:diagnosis[selected_diagnosis]}
    }

    if(modals.diagnosis_modal.open){
      modalcontent = (
        <EditDiagnosisModal
          origin_data={diagnosis_data}
          data={modals.diagnosis_modal}
          content={diagnosis_content}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('diagnosis_modal')}
          onSubmit={this.onEditDiagnosis}
        />
      )
    }

    if(modals.recipe_details_modal.open){
      modalcontent = (
        <EditRecipeDetailsModal
          origin_data={recipe}
          data={modals.recipe_details_modal}
          content={content.recipe_details_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('recipe_details_modal')}
          onSubmit={(data) => this.onEditRecipe(data,'recipe_details_modal')}
        />
      )
    }

    if(modals.recipe_indications_modal.open){
      modalcontent = (
        <EditRecipeIndicationsModal
          origin_data={recipe}
          data={modals.recipe_indications_modal}
          content={content.recipe_indications_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('recipe_indications_modal')}
          onSubmit={(data) => this.onEditRecipe(data,'recipe_indications_modal')}
        />
      )
    }

    if(modals.add_medication_modal.open){
      modalcontent = (
        <AddMedicationModal
          catalogs={catalogs}
          origin_data={recipe}
          data={modals.add_medication_modal}
          content={content.add_medication_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('add_medication_modal')}
          onSubmit={this.onAddMedication}
        />
      )
    }

    if(modals.edit_medication_modal.open){
      modalcontent = (
        <EditMedicationModal
          catalogs={catalogs}
          origin_data={selected_medication}
          data={modals.edit_medication_modal}
          content={content.edit_medication_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('edit_medication_modal')}
          onSubmit={this.onEditMedication}
        />
      )
    }

    if(modals.delete_medication_modal.open){
      modalcontent=(
        <WarningModal 
          data={modals.delete_medication_modal}
          content={content.delete_medication_modal}
          onClose={() => this.onCloseModal('delete_medication_modal')}
          onDelete={this.onDeleteMedication}
        />
      )
    }
    
    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
        onReturn={this.onReturn}
      >
        {modalcontent}
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AppointmentCard 
                    appointment={{...appointment, ...doctor_details}} 
                    translations={translations}
                    onEdit={() => this.onOpenModal('motive_modal')}/>
                </Grid>
                <Grid item xs={12}>
                  <MainDiagnosisCard diagnosis={diagnosis} 
                    onEdit={this.onPreEditDiagnosis}/>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={6}>
              <DiagnosisCard diagnosis={diagnosis} 
              onEdit={this.onPreEditDiagnosis}/>
            </Grid>
            <Grid item xs={12}>
              <RecipeCard recipe={recipe} medications={filter_medications}
                onAdd={() => this.onOpenModal('add_medication_modal')}
                onEdit={this.onPreEditMedication}
                onDelete={this.onPreDeleteMedication}
                onEditRecipeDetails={() => this.onOpenModal('recipe_details_modal')}
                onEditRecipeIndications={() => this.onOpenModal('recipe_indications_modal')}/>
            </Grid>
          </Grid>
        </div>
      </BasicView>
    )
  }

  onPreEditDiagnosis = (key) => {
    this.setState({selected_diagnosis:key})
    this.onOpenModal('diagnosis_modal')
  }

  onPreDeleteMedication = (item) => {
    console.log(item)
    this.setState({selected_medication:item})
    this.onOpenModal('delete_medication_modal')
  }

  
  onPreEditMedication = (item) => {
    console.log(item.id_recipe_medication)
    const {medications} = this.state

    const new_med = medications.find(el => el.id_recipe_medication === item.id_recipe_medication)

    this.setState({selected_medication:new_med})
    this.onOpenModal('edit_medication_modal')
  }

  onUpdateMedications = (data) => {
    let temp = []

    if(data){
      temp = data.map(item => {
        return{
          id_recipe_medication:item.id_recipe_medication,
          "non_registered_medication":item.non_registered_medication, 
          "dosis":item.dosis, 
          "unit_of_measurement":item.unit_of_measurement, 
          "frequency":item.frequency,
          "duration":item.duration,
          "administration_route":item.administration_route, 
          "start_date":item.start_date,
          "end_date":item.end_date
        }
      })
    }

    return temp
  }

  
  onAddMedication = async(data) => {
    const {id} = this.props.match.params
    const {recipe} = this.state

    const type = 'add_medication_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_add_medication(id, recipe.id_recipe, data)
      this.setState({medications:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onEditMedication = async(data) => {
    const {id} = this.props.match.params
    const {recipe, selected_medication} = this.state 

    const type = 'edit_medication_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_medication(id, recipe.id_recipe, selected_medication.id_recipe_medication, data)
      this.setState({medications:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onDeleteMedication = async() => {
    const {id} = this.props.match.params
    const {recipe, selected_medication} = this.state 

    const type = 'delete_medication_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_delete_medication(id, recipe.id_recipe, selected_medication.id_recipe_medication)
      this.setState({medications:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }
  
  onEditAppointment = async(data) => {
    const {id, appointment_id} = this.props.match.params

    const type = 'motive_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_appointment(id,appointment_id, data)
      this.setState({appointment:{...response}})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onEditDiagnosis = async(data) => {
    const {id} = this.props.match.params
    const {diagnosis, selected_diagnosis} = this.state

    let data2send = {
      [selected_diagnosis]:data.diagnosis
    }

    const type = 'diagnosis_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_diagnosis(id,diagnosis.id_diagnosis, data2send)
      this.setState({diagnosis:{...response}})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onEditRecipe = async(data, type) => {
    const {id} = this.props.match.params
    const {recipe} = this.state
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_recipe(id, recipe.id_recipe, data)
      this.setState({recipe:{...response}})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {id, appointment_id} = this.props.match.params
    this.setState({isloading:true})
    try {
      let response = await request_appointment(appointment_id)
      this.setState({appointment:{...response}})
      const doctor_id = response.doctor_id
      response = await request_doctor_details(doctor_id)
      this.setState({doctor_details:{...response}})
      await this.onRequestDiagnosis(id, appointment_id)
      response = await request_recipe(id, appointment_id)
      this.setState({recipe:response})
      const recipe_id = response.id_recipe
      response = await request_recipe_medications(id, recipe_id)
      this.setState({medications:[...response]})

      
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onRequestDiagnosis = async(patient_id, appointment_id) => {
    try{
      let response = await request_diagnosis(patient_id, appointment_id)
      this.setState({diagnosis:response})
    }catch(e){
      console.log(e)
    }
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  

}

const styles = theme => ({
  root:{
  },
})

export default withStyles(styles)(AppointmentView)
export const formData = {
  current_conditions:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'current_conditions',
      type:'text',
      fullWidth: true,
      multiline:true,
      rows:6
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  pathological_history:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'pathological_history',
      type:'text',
      fullWidth: true,
      multiline:true,
      rows:6
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  family_history:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'family_history',
      type:'text',
      fullWidth: true,
      multiline:true,
      rows:6
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  tobacco_condition_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'tobacco_condition_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  alcohol_condition_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'alcohol_condition_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  home_services:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'home_services',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  home_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'home_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  home_pets:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'home_pets',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  inmunization_card:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'inmunization_card',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  
}
import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { grey, amber, red } from '@material-ui/core/colors';
import { Icon, Typography, Grid, IconButton, Hidden, TableHead, TableRow, 
  TableCell, TableContainer, Table, TableBody} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ContentText from '../Texts/ContentText';


const header_styles = (theme) => ({
  container:{
    padding:'8px',
  },
  text:{
    color: grey[500],
    fontWeight:'500'
  },
  textaction:{
    color: grey[500],
    fontWeight:'500',
    padding:'0px 40px'
  }
})

class HeaderTableW extends Component {

  render(){

    const {classes, data} = this.props;

    return(
      <TableHead>
          <TableRow>
            {data.map((label, index) => {
              if(index === 0)
                return <TableCell className={classes.text}>{label}</TableCell>
              else
                return <TableCell className={classes.text} align="right">{label}</TableCell>
            })}
          </TableRow>
      </TableHead>
    )
  }
}

const HeaderTable = withStyles(header_styles)(HeaderTableW);

const styles = (theme) => ({
  card:{borderRadius:'16px'},
  container:{
    padding:'8px', 
    paddingLeft:'8px', 
    borderTop:`1px solid ${grey[200]}`,
    cursor:'pointer',
    '&:hover':{
      backgroundColor: grey[100]
    }
  },
  folder:{color:amber[500]},
  text:{color:grey[900], fontWeight:'500'},
  caption:{color:grey[700]}
})

class DocumentItemW extends Component{

  render(){
    const {classes, disabled, onUpload, onDownload, onDelete, 
      dataItem, icon, permissions, key, onEdit} = this.props;
    const {data} = dataItem
  
    const keys = Object.keys(data)
    keys.splice(0, 1)
    const indexDetails = keys.indexOf("description")
    if (indexDetails > -1)
      keys.splice(indexDetails, 1);
  
    const delete_color = red[700]

    return(
      <TableRow key={key} className={classes.container}>
        <Hidden smDown>
          <TableCell component="th" scope="row" onClick={() => {onEdit(dataItem)}}>
            <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={1}>
              <Grid item>
                <Icon className={classes.folder}>{icon}</Icon>
              </Grid>
              <Grid item xs>
                <Grid container direction='column' justify='flex-start' alignItems='stretch'>
                  <Grid item>
                    <Typography variant='body2' className={classes.text}>{data.title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' className={classes.caption}>{data.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
          {
            keys.map(item => {
              return(
                <TableCell align="right" onClick={() => {onEdit(dataItem)}}>
                  <Typography variant='body2' className={classes.text}>{data[item]}</Typography>
                </TableCell>
              )
            })
          }
          <TableCell align="right">
            <Grid container direction='row' justify='flex-end' alignItems='center'>
              {
                permissions.EDIT ? 
                <Grid item>
                  <IconButton color='primary' onClick={() => onUpload(dataItem)}>
                    <Icon fontSize='small'>cloud_upload</Icon>
                  </IconButton>
                </Grid> : null
              }
              <Grid item>
                <IconButton color='primary' disabled={disabled}  onClick={() => onDownload(dataItem)}>
                  <Icon fontSize='small'>cloud_download</Icon>
                </IconButton>
              </Grid>
              {
                permissions.DELETE ? 
                <Grid item>
                  <IconButton onClick={() => onDelete(dataItem)}>
                    <Icon fontSize='small' style={{color:delete_color}}>close</Icon>
                  </IconButton>
                </Grid> : null
              }
            </Grid>
          </TableCell>
        </Hidden>
        <Hidden mdUp>
          <TableCell component="th" scope="row" onClick={() => {onEdit(dataItem)}}>
            <Grid container wrap='nowrap' alignItems='center' spacing={1}>
              <Grid item>
                <Icon className={classes.folder}>{icon}</Icon>
              </Grid>
              <Grid item xs>
                <Typography variant='body2' className={classes.name}>{data.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' className={classes.caption}>{data.description}</Typography>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right" onClick={() => {onEdit(dataItem)}}>
            <Typography variant='body2' className={classes.text}>{data.date}</Typography>
          </TableCell>
          <TableCell align="right">
            <Grid container alignItems='center' spacing={1}>
            {
                permissions.EDIT ? 
                <Grid item>
                  <IconButton color='primary' onClick={() => onUpload(dataItem)}>
                    <Icon fontSize='small'>cloud_upload</Icon>
                  </IconButton>
                </Grid> : null
              }
              <Grid item>
                <IconButton color='primary' disabled={disabled}  onClick={() => onDownload(dataItem)}>
                  <Icon fontSize='small'>cloud_download</Icon>
                </IconButton>
              </Grid>
              {
                permissions.DELETE ? 
                <Grid item>
                  <IconButton  onClick={() => onDelete(dataItem)}>
                    <Icon fontSize='small' style={{color:delete_color}}>close</Icon>
                  </IconButton>
                </Grid> : null
              }
            </Grid>
          </TableCell>
        </Hidden>
      </TableRow>
    )
  }
}

const DocumentItem = withStyles(styles)(DocumentItemW);

class DocumentTable extends Component {
  render(){

    const {header, icon, data, nodata, onEdit, onUpload, 
      onDownload, onDelete, permissions} = this.props;

    let filesView = null;
    let isData = false

    if(data && data.length > 0){
      isData = true
      filesView = data.map((item,key) => {
        return(
          <DocumentItem 
            key={key.toString()}
            icon={icon}
            dataItem={item}
            disabled={!item.status}
            onEdit={onEdit}
            onUpload={onUpload}
            onDownload={onDownload}
            onDelete={onDelete}
            permissions={permissions}
          />
        )
      })
    }
      


    return(
      <div>
        <Grid container style={{maxHeight:'500px',overflowY:'auto', overflowX:'hidden'}}>
          <Grid item xs={12}>
            {isData ? (
              <TableContainer component={Paper}>
              <Table>
                <HeaderTable data={header}/>
                <TableBody>
                  {filesView}
                </TableBody>
              </Table>
            </TableContainer>
            ) : (
              <Grid container justify='center'>
                <Grid item>
                  <ContentText text={nodata} medium />
                </Grid>
              </Grid>
            )

            }
            
          </Grid>
          
        </Grid>

      </div>
    )
  }
}

export default DocumentTable;
import React, { Component } from 'react';
import BasicView from '../../../components/Layouts/BasicView/BasicView';
import { contentData } from './content';
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import ProfileForm from './components/ProfileForm/ProfileForm';
import { request_add_patient, request_update_me, request_delete_image, request_upload_image, request_update_bio, request_doctor_translations } from './requests';
import EditPassworModal from './modals/EditPassworModal/EditPassworModal';
import ProfilePictureModal from '../../../components/Modals/ProfilePictureModal/ProfilePictureModal';


class AddPatient extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    user:null,
    doctor_translations:null,
    modals:{
      password_modal:{open:false,issending:false,error:null},
      image_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language, catalogs} = this.props
    const {user, isloading, issending, error, modals, doctor_translations} = this.state

    const content = contentData[language]

    let modalContent = null

    if(modals.password_modal.open){
      modalContent = (
        <EditPassworModal
          language={language}
          content={content.password_modal}
          data={modals.password_modal}
          onClose={() => this.onCloseModal('password_modal')}
          onSubmit={this.onChangePassword}
        />
      )
    }

    if(modals.image_modal.open){
      modalContent = (
        <ProfilePictureModal
          data={modals.image_modal}
          language={language}
          onClose={() => this.onCloseModal('image_modal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }

    return(
      <BasicView
        isloading={isloading}
        content={content.layout}
        onReturn={this.onReturn}
      >
        <div>
          {modalContent}
          <Grid container>
            <Grid item xs={12}>
              <CardWrapper>
                <div className={classes.root}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <ProfileForm 
                        doctor_translations={doctor_translations}
                        language={language} 
                        catalogs={catalogs} 
                        isloading={issending}
                        error={error}
                        onSubmit={this.onSubmit}
                        onCancel={this.onReturn}/>
                    </Grid>
                  </Grid>
                </div>
              </CardWrapper>
            </Grid>
            
          </Grid>
        </div>

      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try {
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onSubmit = async(data) => {
    console.log(data)
    if(data){
      if(JSON.stringify(data) !== "{}"){
        this.setState({issending:true})
        try {
          let response = await request_add_patient(data)
          console.log(response)
          this.setState({error:null, issending:null})
          this.props.history.push('/patients')
        } catch (error) {
          if(error.response){
            this.setState({issending:false})
            let e = {...error.response.data.error}
            this.setState({error:`#${e.code}, ${e.message}`})
          }else{
            this.setState({error:`No Internet`})
          }
          
        }
        
      }
    }
  }

  onChangePassword = async(data) => {
    const type = 'password_modal'
    
    if(data){
      if(JSON.stringify(data) !== "{}"){
        try {
          this.onEditModal(type,{issending:true})
          let response = await request_update_me(data)
          this.setState({user:{...response}})
          this.onCloseModal(type)
        } catch (error) {
          this.onEditModal(type,{issending:false})
          if(error.response){
            let e = {...error.response.data.error}
            this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
          }else{
            this.onEditModal(type,{error:`No Internet`})
          }
        }
      }
    }
  }


  onSubmitPicture = async(type, file) => {

    const type_modal = 'image_modal'

    try {
      this.onEditModal(type_modal,{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_upload_image(file[0], ext);
      this.setState({user:{...response}})
      this.onCloseModal(type_modal)
    } catch (error) {
      this.onEditModal(type_modal,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type_modal,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type_modal,{error:`No Internet`})
      }
    }
  }

  onDeletePicture = async() => {
    const type = 'image_modal'
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_delete_image();
      this.setState({user:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onReturn = () => {
    this.props.history.push('/patients')
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }


}

const styles = theme => ({
  root:{
    padding:32
  },
  link:{
    cursor:'pointer'
  }
})

export default withStyles(styles)(AddPatient)
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Historia Clínica',
      navigation:[
        {name:'Historia',path:'/history'},
      ],
    },
    documents:{
      title:'Documentos del paciente',
      permissions:{ EDIT:true, DELETE:true },
      noDataTable:'No hay documentos registrados',
      icon:'folder'
    },
    header:['Archivos', 'Fecha', 'Acciones'],
    buttons:{
      list:{
        icon:'list'
      },
      timeline:{
        icon:'schedule'
      },
      add:{
        label:'Agregar',
        icon:'add'
      },
      delete:'Eliminar',
      edit:'Editar'
    }
  }
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import MasterTableA from '../../../components/Tables/MasterTableA'
import { request_appointments } from './requests';
import moment from 'moment'

const appointment_urls = [
  '/appointment/doctor/me',
  '/appointment/doctor/me/following',
  '/appointment/doctor/me/dates'
]

class Appointments extends Component {

  state = {
    isloading:false,
    users:[],
    appointments:[],
    filter:{
      id:1,
      value:null,
      type:null
    },
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language, translations} = this.props
    const {isloading, table_config, filter, appointments} = this.state
    const content = contentData[language]
    //console.log(appointments)

    let appointments_data = [...appointments]

    // Add extra parameter to handle the correct info to show in table
    if(appointments){
      appointments.forEach((item, key) => {
        const appointment_type = translations[item.appointment_type_text_id]
        const appointment_date = moment(item.start_date).format('DD/MM/YYYY')
        const start_hour = `${moment(item.start_date).format('HH:mm')} hrs`
        const end_hour = `${moment(item.end_date).format('HH:mm')} hrs`
        appointments_data[key] = {...item, 
          appointment_type:appointment_type,
          appointment_date:appointment_date,
          start_hour: start_hour,
          end_hour:end_hour
        }
      })
    }

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
      >
        <div className={classes.root}>
          <MasterTableA 
            data={appointments_data}
            filter={filter}
            table={content.table} 
            config={table_config}
            onSelectedRow={this.onSelectedRow}
            onSearch={this.onSearch}
            onChangePageNumber={this.onChangePageNumber}
            onChangePage={this.onChangePage}
            onChangeFilter={this.onChangeFilter}/>
        </div>
      </BasicView>
    )
  }

  onAddUser = () => {
    this.props.history.push(`/users/add`)
  }

  onChangeFilter = async(filter) => {
    const {table_config} = this.state
    this.setState({filter:filter})
    const url = appointment_urls[filter.id-1]

    let params = {
      limit:table_config.rows,
      offset:table_config.rows*table_config.page
    }

    if(filter){
      switch(filter.type){
        case 'date':
          if(filter.value){
            params = {...params, ...filter.value}
          }else{
            params = {...params, 
              start_date:moment().format('YYYY-MM-DD'),
              end_date:moment().add(1,'days').format('YYYY-MM-DD')
            }
          }
          break
        default:
          break

      }
    }

    try {
      let response = await request_appointments(url, params)
      this.setState({appointments:[...response.doctor_appointment]})
      this.updateTableConfig('count',response.count)
    } catch (error) {
      console.log(error)
    }
  }

  onSelectedRow = (item) => {
    window.open(`https://celudoc.com/`, '_blank')
    //this.props.history.push(`/appointments/${item.id_appointment}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {table_config, filter} = this.state
    this.setState({isloading:true})
    const url = appointment_urls[filter.id-1]
    try {
      let response = await request_appointments(url, {
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({appointments:[...response.doctor_appointment]})
      this.updateTableConfig('count',response.count)
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  updateTableConfig = (key, data) => {
    const {table_config} = this.state
    let temp = {...table_config}
    temp[key] = data
    this.setState({table_config:{...temp}})
  }

}

const styles = theme => ({
  root:{
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(Appointments)
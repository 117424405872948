import React from 'react';
import {Paper, Grid, IconButton, Icon} from '@material-ui/core'
import Subtitle from '../../Texts/Subtitle';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper:{
    borderRadius:32,
    paddingTop:8,
    paddingBottom:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  link:{
    color:grey[500],
    fontWeight:600,
    fontSize:14,
    '&:hover':{
      color:theme.palette.primary.main,
      cursor:'pointer',
    }
  }
}))

const CardWrapper = (props) => {

  const {title, color, onEdit, onClickLink, link, nopadding} = props
  let toolbarContent = null

  const classes = useStyles()

  if(title){
    let buttonContent = null
    if(onEdit){
      buttonContent = (
        <Grid item>
          <IconButton size='small' onClick={onEdit}>
            <Icon fontSize='small' style={{color: color ? color : grey[500]}}>edit</Icon>
          </IconButton>
        </Grid>
      )
    }

    if(link){
      buttonContent = (
        <Grid item>
          <div className={classes.link} onClick={onClickLink} style={{color: color ? color : null}}>
            {link}
          </div>
        </Grid>
      )
    }

    toolbarContent = (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs>
          <Subtitle text={title} medium color={color ? color : grey[500]}/>
        </Grid>
        {buttonContent}
      </Grid>
    </Grid>
    )
  }

  return(
    <Grid container>
      {toolbarContent}
      <Grid item xs={12}>
        <Paper className={classes.paper} style={nopadding ? {padding:0} : null}>
          {props.children}
        </Paper>
      </Grid>
    </Grid>
    
  )
}

export default CardWrapper;
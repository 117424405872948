export const contentData = {
  spanish:{
    form:{
      non_registered_medication:{
        label:'Medicamento',
        placeholder:'Medicamento',
        helper:'Medicamento no válido'
      },
      dosis:{
        label:'Dósis',
        placeholder:'Dósis',
        helper:'Dósis no válida'
      },
      unit_of_measurement:{
        label:'Unidad',
        placeholder:'Unidad',
        helper:'Unidad no válida'
      },
      frequency:{
        label:'Frecuencia',
        placeholder:'Frecuencia',
        helper:'Frecuencia no válida'
      },
      duration:{
        label:'Duración',
        placeholder:'Duración',
        helper:'Duración no válida'
      },
      administration_route_id:{
        label:'Vía de administración',
        placeholder:'Seleccione una opcion',
        helper:'Vía de administración no válida'
      },
      start_date:{
        label:'Fecha de inicio',
        placeholder:'Fecha de inicio',
        helper:'Fecha no válida'
      },
      end_date:{
        label:'Fecha de fin',
        placeholder:'Fecha de fin',
        helper:'Fecha no válida'
      },
    }
  }
}
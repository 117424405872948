export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Vacunación adulto',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Vacunación Adulto',path:'/patients/:id/vaccination/adult'},
      ]
    },
    vacinnation_modal:{
      title:'Editar vacuna',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    infant_vacinnation:{
      header:[
        {text:'Vacuna'},
        {text:'Enfermedad'},
        {text:'Dósis'},
        {text:'Frecuencia'},
        {text:'Fecha'},
      ],
      body:[
        {
          vaccine:'MMR',
          illness:'Sarampión y Rubéola',
          data:[
            {dose:'Primera', frequency:'Al primer contacto', data:null, id:'mmr_1'},
            {dose:'Segunda', frequency:'4 semanas después', data:null, id:'mmr_2'},
          ]
        },
        {
          vaccine:'TD',
          illness:'Tétanos y Difteria',
          data:[
            {dose:'Primera', frequency:'Dosis inicial', data:null, id:'td_1'},
            {dose:'Segunda', frequency:'1 mes después', data:null, id:'td_2'},
            {dose:'Tercera', frequency:'12 meses después', data:null, id:'td_3'},
          ]
        },
        {
          vaccine:'Influenza',
          illness:'Influeza',
          data:[
            {dose:'Primera', frequency:'6 meses', data:null, id:'influenza_1'},
            {dose:'Segunda', frequency:'7 meses', data:null, id:'influenza_2'},
            {dose:'Tercera', frequency:'Anual', data:null, id:'influenza_3'},
          ]
        },
        {
          vaccine:'Neumococo',
          illness:'Neumococo',
          data:[
            {dose:'Refuerzo', frequency:'Anual', data:null, id:'neumococo'},
          ]
        },
        {
          vaccine:'HBV',
          illness:'Hepatitis B',
          data:[
            {dose:'Primera', frequency:'Después de 18 años', data:null, id:'hbv_1'},
            {dose:'Segunda', frequency:'1 mes después', data:null, id:'hbv_2'},
            {dose:'Tercera', frequency:'6 meses después', data:null, id:'hbv_3'},
          ]
        },
        {
          vaccine:'HBV',
          illness:'Hepatitis A',
          data:[
            {dose:'Primera', frequency:'Después de 18 años', data:null, id:'hav_1'},
            {dose:'Segunda', frequency:'6 meses después', data:null, id:'hav_2'},
          ]
        },
        {
          vaccine:'Varicela',
          illness:'Varicela',
          data:[
            {dose:'Primera', frequency:'Después de 19 años', data:null, id:'varicela_1'},
            {dose:'Segunda', frequency:'6-10 semanas después', data:null, id:'varicela_2'},
          ]
        },
       
        
      ]
    }
  }
}
import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid, fade } from '@material-ui/core';
import LongTextData from '../../../../components/DisplayData/LongTextData';
import MedicationTable from '../../../../components/Tables/MedicationTable';

class RecipeCard extends Component {
  render(){

    const {classes, recipe, medications, onAdd, onEdit, onDelete, onEditRecipeDetails, onEditRecipeIndications} = this.props
    console.log(recipe)
    return(
      <CardWrapper
        title='Receta médica'
      >
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div>
                <MedicationTable 
                  onAdd={onAdd}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  medications={medications}/>
              </div>

            </Grid>
            <Grid item xs={12}>
              <LongTextData 
                id='recipe_details'
                label='Detalles'
                text={recipe ? recipe.recipe_details : '-'}
                onEdit={onEditRecipeDetails}
              />
            </Grid>
            <Grid item xs={12}>
              <LongTextData 
                id='additional_indications'
                label='Indicaciones adicionales'
                text={recipe ? recipe.additional_indications : '-'}
                onEdit={onEditRecipeIndications}
              />
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  onEditData = () => {
  }
}

const styles = theme => ({
  root:{padding:24},
  container:{
    //background: fade(theme.palette.primary.main,0.2),
    padding:8,
    borderRadius:8,
    minHeight:260
  }
})

export default withStyles(styles)(RecipeCard)
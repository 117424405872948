export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Editar historia médica',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Editar historia',path:'/patients/:id/edit-patient-history'},
      ]
    },
    
  }
}
export const contentData = {
  spanish:{
    form:{
      recipe_details:{
        label:'Describa los detalles',
        placeholder:'Detalles...',
        helper:'Detalles no válidos'
      },
    }
  }
}
import React, { Component } from 'react';
import { withStyles,  Grid } from '@material-ui/core';
import BasicModal from '../../../../../../components/Modals/BasicModal';
import {contentData} from './content'
import Subtitle from '../../../../../../components/Texts/Subtitle';
import logo from '../../../../../../assets/doctor.png'

class AddAppointmentModal extends Component {
  
  render(){

    const {onClose, data, language, classes, onSubmit} = this.props

    const content = contentData[language]


    return(
      <BasicModal
        open={true}
        onClose={onClose}
        content={content.modal}
        onSubmit={onSubmit}
        isloading={data ? data.issending : null}
        error={data ? data.error : null}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <img src={logo} alt='' className={classes.image}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Subtitle text={content.message} medium variant='light' align='center'/>
          </Grid>
        </Grid>
      </BasicModal>
        
    )
  }

}

const styles = theme => ({
  image:{
    width:120
  }
})

export default withStyles(styles)(AddAppointmentModal)
import { palette } from "../../../variables/config";

import oxygen_icon from '../../../assets/medicalicons/oxygen.svg'
import cardiogram_icon from '../../../assets/medicalicons/cardiogram.svg'
import lungs_icon from '../../../assets/medicalicons/lungs-with-the-trachea.svg'
import heart_icon from '../../../assets/medicalicons/heart.svg'
import drop_icon from '../../../assets/medicalicons/drop.svg'


export const contentData = {
  spanish:{
    nodata:'-',
    novaccination:'-',
    layout:{
      title:'Detalles del paciente',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        //{name:'Editar',path:'/patients/:id/edit'},
      ],
      menu:[
        {id:1, label:'Resumen'},
        {id:2, label:'Signos Vitales'},
        {id:3, label:'Consultas'},
        {id:5, label:'Imágenes'},
        {id:6, label:'Laboratorio'},

      ],
    },
    register_modal:{
      title:'Registrar evento',
      cancel_button:'Cancelar',
      submit_button:'Registrar'
    },
    add_vital_signs_modal:{
      title:'Agregar signos vitales',
      cancel_button:'Cancelar',
      submit_button:'Registrar'
    },
    edit_image_modal:{
      title:'Editar datos de imagen',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    edit_document_modal:{
      title:'Editar laboratorio',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    delete_document_modal:{
      title:'¿Estás seguro?',
      message:'¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido',
      cancelButton:'Cancelar',
      deleteButton:'Eliminar'
    },
    vital_signs:[
      {id:1, name:'Temperatura',timestamp:'2020-01-20 08:00:00',value:'36.5',unit:'°C',image:oxygen_icon},
      {id:2, name:'F. Cardiaca',timestamp:'2020-01-20 08:00:00',value:'86',unit:'lpm',image:cardiogram_icon},
      {id:3, name:'F. Respiratoria',timestamp:'2020-01-20 08:00:00',value:'14',unit:'rpm',image:lungs_icon},
      {id:4, name:'Glucosa',timestamp:'2020-01-20 08:00:00',value:'81',unit:'mg/dl',image:drop_icon},
      {id:5, name:'Sat. O2',timestamp:'2020-01-20 08:00:00',value:'91',unit:'%',image:oxygen_icon},
      {id:6, name:'P. Sistolica',timestamp:'2020-01-20 08:00:00',value:'140',unit:'mmHg',image:heart_icon},
    ],
    attendance_card:{
      nodata:'Sin registro'
    },
    profile_card:{
      form:{
        first_name:{
          label:'Nombre(s)'
        },
        fathers_last_name:{
          label:'Apellido Paterno'
        },
        mothers_last_name:{
          label:'Apellido Materno'
        },
        gender_text_id:{
          label:'Género', 
          translate:true,
        },
        email:{
          label:'Correo electrónico',
          icon:'mail',
          iconColor:palette.primary.main
        },
        mobile:{
          label:'Celular',
          icon:'phone',
          iconColor:palette.primary.main
        },

        blood_type:{
          label:'Tipo de sangre',
        },
        birth_date:{
          label:'Fecha de nacimiento'
          
        },
        curp:{
          label:'CURP'
        },
        fixed_phone:{
          label:'Teléfono fijo',
          icon:'phone',
          iconColor:palette.primary.main
        },
        timezone:{
          label:'Zona horaria'
        },
        occupation:{
          label:'Ocupación'
        },
        religion:{
          label:'Religión',
        },
        details:{
          label:'Detalles'
        }
      }
    },
    infant_vaccination:{
      bcg: {label:'BGC'},
      hepatitis_b_1: {label:'Hepatitis B 1a'},
      hepatitis_b_2: {label:'Hepatitis B 2a'},
      hepatitis_b_3: {label:'Hepatitis B 3a'},
      pentavalente_1: {label:'Pentevalente 1a'},
      pentavalente_2: {label:'Pentevalente 2a'},
      pentavalente_3: {label:'Pentevalente 3a'},
      pentavalente_4: {label:'Pentevalente 4a'},
      dpt: {label:'DPT'},
      rotavirus_1: {label:'Rotavirus 1a'},
      rotavirus_2: {label:'Rotavirus 2a'},
      hepatitis_a_1: {label:'Hepatitis A 1a'},
      hepatitis_a_2: {label:'Hepatitis A 2a'},
      neumococica_1: {label:'Neumocócica 1a'},
      neumococica_2: {label:'Neumocócica 2a'},
      neumococica_3: {label:'Neumocócica 3a'},
      antiinfluenza_1: {label:'Influenza 1a'},
      antiinfluenza_2: {label:'Influenza 2a'},
      antiinfluenza_annual: {label:'Influenza Anual'},
      srp_1: {label:'SRP 1a'},
      srp_2: {label:'SRP 2a'},
      varicela_1: {label:'Varicela 1a'},
      varicela_2: {label:'Varicela 2a'},
      vph_1: {label:'VPH 1a'},
      vph_2: {label:'VPH 2a'},
      vph_3: {label:'VPH 3a'},
      td: {label:'TD'},
      sabin: {label:'Sabin'},
      sr: {label:'SR'},
      hepatitis_b_alt_1: {label:'Hepatitis B Alt. 1a'},
      hepatitis_b_alt_2: {label:'Hepatitis B Alt. 2a'}
    },
    adult_vaccination:{
      mmr_1: {label:'MMR 1a'},
      mmr_2: {label:'MMR 2a'},
      td_1: {label:'TD 1a'},
      td_2: {label:'TD 2a'},
      td_3: {label:'TD 2a'},
      influenza_1: {label:'Influenza 1a'},
      influenza_2: {label:'Influenza 2a'},
      influenza_3: {label:'Influenza 3a'},
      neumococo: {label:'Neumococo'},
      hbv_1: {label:'HBV 1a'},
      hbv_2: {label:'HBV 2a'},
      hbv_3: {label:'HBV 3a'},
      hav_1: {label:'HAV 1a'},
      hav_2: {label:'HAV 2a'},
      varicela_1: {label:'Varicela 1a'},
      varicela_2: {label:'Varicela 2a'},
    },

  }
}
export const formData = {
  date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'date',
    }
  },
}
import {mainServer} from '../../../../variables/config'

export const request_infant_vaccination = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/infant`)
  return response.data.data.infant_vaccination_found
}

export const request_update_infant_vaccination = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/vaccination/${id}/infant`, data)
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/infant`)
  return response.data.data.infant_vaccination_found
}
import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid } from '@material-ui/core';
import moment from 'moment'
import { palette } from '../../../../variables/config';
import EvaluationItem from '../../../../components/DisplayData/EvaluationItem';

class EvaluationCard extends Component {
  render(){

    const {classes, appointment, onEdit} = this.props

    //let appointment_data = this.onUpdateData(content.form, appointment)

    let evaluation_data = {...content.form}

    return(
      <CardWrapper
        title='Evaluaciones'
        link='Editar'
        onClickLink={onEdit}
      >
        <div className={classes.root}>
          <Grid container spacing={1}>
            {Object.keys(evaluation_data).map((item,key)=>{
              return(
                <Grid item xs={12}>
                  <EvaluationItem 
                    evaluation={3.1}
                    data={evaluation_data[item]}/>
                </Grid>
              )
            })}
          </Grid>
          
        </div>
      </CardWrapper>
    )
  }

  onUpdateData = (form, data) => {
    const {translations} = this.props
    let temp = {...form}
    Object.keys(form).forEach(item => {
      if(data){
        switch(form[item].type){
          case 'full_date':
            const aux = moment(data[item])
            temp[item].value = aux.isValid() ? `${aux.format('DD/MM/YYYY HH:mm')}hrs` : null
            break;
          case 'translate':
            temp[item].value = data[item] ? translations[data[item]] : null
            break;
          default:
            temp[item].value = data[item]
            break
        }
      }
    })
    return temp
  }
}

const styles = theme => ({
  root:{padding:24}
})

export default withStyles(styles)(EvaluationCard)

const content = {
  go2metting:'Ir a cita',
  form:{
    doctor_video_quality:{
      label:'Paciente',
      icon:'videocam',
      iconColor:palette.primary.main,
      type:'rating'
    },
    doctor_audio_quality:{
      label:'Tipo de cita',
      icon:'music_note',
      iconColor:palette.primary.main,
      type:'rating'
    },
    doctor_call_quality:{
      label:'Inicio',
      icon:'call',
      iconColor:palette.primary.main,
      type:'rating'
    },
    doctor_call_comments:{
      label:'Comentarios',
      icon:'mode_comment',
      iconColor:palette.primary.main,
      type:'text'
    },
    user_evaluation:{
      label:'Paciente',
      icon:'person',
      iconColor:palette.primary.main,
      type:'rating'
    },
    doctor_user_comments:{
      label:'Comentarios',
      icon:'mode_comment',
      iconColor:palette.primary.main,
      type:'text'
    }
  }
}
export const catalogs={
  spanish:{
    languages:[{id:1,value:1,label:'Español'},{id:2,value:2,label:'Inglés'},],
    user_genders:[{id:1,value:1,label:'Hombre'},{id:2,value:2,label:'Mujer'},{id:3,value:3,label:'Otro'},],
    currencies:[{id:1,value:1,label:'Dólar Americano'},{id:2,value:2,label:'Peso Mexicano'},],
    user_types:[{id:1,value:1,label:'Administrador'},{id:2,value:2,label:'Doctor'},{id:3,value:3,label:'Usuario'},],
    appointment_types:[{id:1,value:1,label:'Videollamada'},{id:2,value:2,label:'Llamada'},{id:3,value:3,label:'Chat'},],
    payment_concepts:[{id:1,value:1,label:'Cita de Dermatología'},{id:2,value:2,label:'Cita de Medicina General'},{id:3,value:3,label:'Cita de Nutrición'},{id:4,value:4,label:'Cita de Psicología'},],
    payment_statuses:[{id:1,value:1,label:'Pendiente'},{id:2,value:2,label:'Rechazado'},{id:3,value:3,label:'Expirado'},{id:4,value:4,label:'Pagado'},{id:5,value:5,label:'Reembolsado'},{id:6,value:6,label:'Parcialmente Reembolsado'},{id:7,value:7,label:'Recargado'},{id:8,value:8,label:'Pre-Autorizado'},{id:9,value:9,label:'Anulado'},],
    medications:[],
    administration_routes:[{id:1,value:1,label:'Oral'},{id:2,value:2,label:'Tópica'},{id:3,value:3,label:'Respiratoria'},{id:4,value:4,label:'Subcutánea'}],
    medication_specialities:[],
    address_types:[{id:1,value:1,label:'Principal'},{id:2,value:2,label:'Lugar de nacimiento'},{id:3,value:3,label:'Envío'},],
    countries:[{id:1,value:1,label:'México'},],
    user_statuses:[{id:1,value:1,label:'No Registrado'},{id:2,value:2,label:'Invitado'},{id:3,value:3,label:'Registrado'},],
    doctor_availabilities_statuses:[{id:1,value:1,label:'Disponible'},{id:2,value:2,label:'Reservada'},{id:3,value:3,label:'Agendada'},],
    specialities:[{id:1,value:1,label:'Dermatología'},{id:2,value:2,label:'Medicina General'},{id:3,value:3,label:'Nutrición'},{id:4,value:4,label:'Psicología'},],
    patient_medical_history_statuse:[{id:1,value:1,label:'Pendiente de aprovación'},{id:2,value:2,label:'Aprovado'},],
    blood_types:[{id:1,value:1,label:'O+'},{id:2,value:2,label:'A+'},{id:3,value:3,label:'B+'},{id:4,value:4,label:'AB+'},{id:5,value:5,label:'O-'},{id:6,value:6,label:'A-'},{id:7,value:7,label:'B-'},{id:8,value:8,label:'AB-'},],
    phatological_conditions:[{id:1,value:1,label:'Arterial hypertension'},{id:2,value:2,label:'Tuberculosis'},{id:3,value:3,label:'Diabetes'},{id:4,value:4,label:'Obesity'},{id:5,value:5,label:'Measles'},{id:6,value:6,label:'Rubella'},{id:7,value:7,label:'Whooping cough'},{id:8,value:8,label:'Chickenpox'},{id:9,value:9,label:'Scarlet fever'},{id:10,value:10,label:'Tonsillitis'},{id:11,value:11,label:'Hepatitis'},{id:12,value:12,label:'Seizures'},{id:13,value:13,label:'Heart disease'},{id:14,value:14,label:'Osteoporosis'},{id:15,value:15,label:'Dyslipidemia'},{id:16,value:16,label:'Cancer'},{id:17,value:17,label:'Arthritis'},{id:18,value:18,label:'Thyroid'},{id:19,value:19,label:'Another condition'},],
    family_conditions:[{id:1,value:1,label:'Diabetes'},{id:2,value:2,label:'Hypertension'},{id:3,value:3,label:'Heart disease'},{id:4,value:4,label:'Osteoporosis'},{id:5,value:5,label:'Tuberculosis'},{id:6,value:6,label:'Cervical cancer'},{id:7,value:7,label:'Breast cancer'},{id:8,value:8,label:'Obesity'},{id:9,value:9,label:'Dyslipidemia'},{id:10,value:10,label:'Renal insufficiency'},{id:11,value:11,label:'Cardiovascular death'},{id:12,value:12,label:'Cerebrovascular diseases'},{id:13,value:13,label:'Smoking'},{id:14,value:14,label:'Thyroid'},{id:15,value:15,label:'Epilepsy'},{id:16,value:16,label:'Another antecedents'},],
    tobacco_conditions:[{id:1,value:1,label:'Always'},{id:2,value:2,label:'Sometimes'},{id:3,value:3,label:'Never'},{id:4,value:4,label:'Former smoker'},],
    alcohol_conditions:[{id:1,value:1,label:'Always'},{id:2,value:2,label:'Sometimes'},{id:3,value:3,label:'Never'},{id:4,value:4,label:'Ex-alcoholic'},],
    home_services:[{id:1,value:1,label:'Drinking water'},{id:2,value:2,label:'Light'},{id:3,value:3,label:'Sewer system'},],
    home_types:[{id:1,value:1,label:'Bad'},{id:2,value:2,label:'Regular'},{id:3,value:3,label:'Good'},],
    home_pets:[{id:1,value:1,label:'Cat'},{id:2,value:2,label:'Dog'},],
    inmunization_types:[{id:1,value:1,label:'Incomplete (shows primer)'},{id:2,value:2,label:'Incomplete (does not show primer)'},{id:3,value:3,label:'Complete (shows primer)'},{id:4,value:4,label:'Complete (does not show primer)'},{id:5,value:5,label:'Updated'},],
    common_allergies:[],
    medication_allergies:[],
  },
  english:{
    languages:[{id:1,value:1,label:'Spanish'},{id:2,value:2,label:'English'},],
    user_genders:[{id:1,value:1,label:'Male'},{id:2,value:2,label:'Female'},{id:3,value:3,label:'Other'},],
    currencies:[{id:1,value:1,label:'American Dollar'},{id:2,value:2,label:'Mexican Peso'},],
    user_types:[{id:1,value:1,label:'Admin'},{id:2,value:2,label:'Doctor'},{id:3,value:3,label:'User'},],
    appointment_types:[{id:1,value:1,label:'Videocall'},{id:2,value:2,label:'Call'},{id:3,value:3,label:'Chat'},],
    payment_concepts:[{id:1,value:1,label:'Dermatology Appointment'},{id:2,value:2,label:'General Medicine Appointment'},{id:3,value:3,label:'Nutrition Appointment'},{id:4,value:4,label:'Psychology Appointment'},],
    payment_statuses:[{id:1,value:1,label:'Pending'},{id:2,value:2,label:'Declined'},{id:3,value:3,label:'Expired'},{id:4,value:4,label:'Paid'},{id:5,value:5,label:'Refunded'},{id:6,value:6,label:'Partially refunded'},{id:7,value:7,label:'Charged back'},{id:8,value:8,label:'Pre authorized'},{id:9,value:9,label:'Voided'},],
    medications:[],
    administration_routes:[],
    medication_specialities:[],
    address_types:[{id:1,value:1,label:'Main'},{id:2,value:2,label:'Birth Place'},{id:3,value:3,label:'Shipping'},],
    countries:[{id:1,value:1,label:'Mexico'},],
    user_statuses:[{id:1,value:1,label:'Not Registered'},{id:2,value:2,label:'Invited'},{id:3,value:3,label:'Registered'},],
    doctor_availabilities_statuses:[{id:1,value:1,label:'Available'},{id:2,value:2,label:'Reserved'},{id:3,value:3,label:'Scheduled'},],
    specialities:[{id:1,value:1,label:'Dermatology'},{id:2,value:2,label:'General Medicine'},{id:3,value:3,label:'Nutrition'},{id:4,value:4,label:'Psychology'},],
    patient_medical_history_statuse:[{id:1,value:1,label:'Pending Approval'},{id:2,value:2,label:'Approved'},],
    blood_types:[{id:1,value:1,label:'O+'},{id:2,value:2,label:'A+'},{id:3,value:3,label:'B+'},{id:4,value:4,label:'AB+'},{id:5,value:5,label:'O-'},{id:6,value:6,label:'A-'},{id:7,value:7,label:'B-'},{id:8,value:8,label:'AB-'},],
    phatological_conditions:[{id:1,value:1,label:'Arterial hypertension'},{id:2,value:2,label:'Tuberculosis'},{id:3,value:3,label:'Diabetes'},{id:4,value:4,label:'Obesity'},{id:5,value:5,label:'Measles'},{id:6,value:6,label:'Rubella'},{id:7,value:7,label:'Whooping cough'},{id:8,value:8,label:'Chickenpox'},{id:9,value:9,label:'Scarlet fever'},{id:10,value:10,label:'Tonsillitis'},{id:11,value:11,label:'Hepatitis'},{id:12,value:12,label:'Seizures'},{id:13,value:13,label:'Heart disease'},{id:14,value:14,label:'Osteoporosis'},{id:15,value:15,label:'Dyslipidemia'},{id:16,value:16,label:'Cancer'},{id:17,value:17,label:'Arthritis'},{id:18,value:18,label:'Thyroid'},{id:19,value:19,label:'Another condition'},],
    family_conditions:[{id:1,value:1,label:'Diabetes'},{id:2,value:2,label:'Hypertension'},{id:3,value:3,label:'Heart disease'},{id:4,value:4,label:'Osteoporosis'},{id:5,value:5,label:'Tuberculosis'},{id:6,value:6,label:'Cervical cancer'},{id:7,value:7,label:'Breast cancer'},{id:8,value:8,label:'Obesity'},{id:9,value:9,label:'Dyslipidemia'},{id:10,value:10,label:'Renal insufficiency'},{id:11,value:11,label:'Cardiovascular death'},{id:12,value:12,label:'Cerebrovascular diseases'},{id:13,value:13,label:'Smoking'},{id:14,value:14,label:'Thyroid'},{id:15,value:15,label:'Epilepsy'},{id:16,value:16,label:'Another antecedents'},],
    tobacco_conditions:[{id:1,value:1,label:'Always'},{id:2,value:2,label:'Sometimes'},{id:3,value:3,label:'Never'},{id:4,value:4,label:'Former smoker'},],
    alcohol_conditions:[{id:1,value:1,label:'Always'},{id:2,value:2,label:'Sometimes'},{id:3,value:3,label:'Never'},{id:4,value:4,label:'Ex-alcoholic'},],
    home_services:[{id:1,value:1,label:'Drinking water'},{id:2,value:2,label:'Light'},{id:3,value:3,label:'Sewer system'},],
    home_types:[{id:1,value:1,label:'Bad'},{id:2,value:2,label:'Regular'},{id:3,value:3,label:'Good'},],
    home_pets:[{id:1,value:1,label:'Cat'},{id:2,value:2,label:'Dog'},],
    inmunization_types:[{id:1,value:1,label:'Incomplete (shows primer)'},{id:2,value:2,label:'Incomplete (does not show primer)'},{id:3,value:3,label:'Complete (shows primer)'},{id:4,value:4,label:'Complete (does not show primer)'},{id:5,value:5,label:'Updated'},],
    common_allergies:[],
    medication_allergies:[],
  },
}
  
export const formData = {
  weight:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'weight',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  height:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'height',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  temperature:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'temperature',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  heart_rate:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'heart_rate',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  breathing_frequency:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'breathing_frequency',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  blood_glucose:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'blood_glucose',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  o2_saturation:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'o2_saturation',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  systolic_pressure:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'systolic_pressure',
      type:'numeric',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  
}
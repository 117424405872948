import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import MasterTableA from '../../../components/Tables/MasterTableA'
import { request_patients } from './requests';
import { Fab, Icon } from '@material-ui/core';

class Patients extends Component {

  state = {
    isloading:false,
    patients:[],
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language} = this.props
    const {isloading, table_config, patients} = this.state
    const content = contentData[language]
    console.log(patients)
    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
      >
        <div className={classes.root}>
          <Fab className={classes.button} color='primary' onClick={this.onAddUser}>
            <Icon >person_add</Icon>
          </Fab>
          <MasterTableA 
            data={patients}
            table={content.table} 
            config={table_config}
            onSelectedRow={this.onSelectedRow}
            onSearch={this.onSearch}
            onChangePageNumber={this.onChangePageNumber}
            onChangePage={this.onChangePage}/>
        </div>
      </BasicView>
    )
  }

  onAddUser = () => {
    this.props.history.push(`/patients/add`)
  }

  onSelectedRow = (item) => {
    this.props.history.push(`/patients/${item.patient_id}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {table_config} = this.state
    this.setState({isloading:true})
    try {
      let response = await request_patients({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({patients:[...response.doctor_patients]})
      this.updateTableConfig('count',response.count)
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  updateTableConfig = (key, data) => {
    const {table_config} = this.state
    let temp = {...table_config}
    temp[key] = data
    this.setState({table_config:{...temp}})
  }

}

const styles = theme => ({
  root:{
    position:'relative'
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(Patients)
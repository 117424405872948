import React, { Component } from 'react';
import MasterTableA from '../../../../components/Tables/MasterTableA';
import { contentData } from './content';
import moment from 'moment'

class VitalSigns extends Component {

  state = {
    isloading:false,
    appointments:[],
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
  }

  render(){

    const {language, vitals_signs, translations, onAdd} = this.props
    const {table_config} = this.state

    const content = contentData[language]

    //console.log(appointments)
    let current_signs = this.onUpdateData(vitals_signs, translations)
    console.log(current_signs)
    return(
      <div>
        <MasterTableA 
          onAdd={onAdd}
          buttons={content.buttons}
          data={current_signs}
          table={content.table} 
          config={table_config}
          onSelectedRow={this.onSelectedRow}
          onSearch={this.onSearch}
          onChangePageNumber={this.onChangePageNumber}
          onChangePage={this.onChangePage}/>
        
      </div>
    )
  }

  onUpdateData = (data) => {
    let temp = [...data]
    data.forEach((item,key)=>{
      temp[key] = {
        id_vital_sign:item.id_vital_sign,
        addition_date: moment(item.addition_date).format('DD/MM/YYYY, HH:mm'),
        weight: item.weight ? `${item.weight}kg` : '-kg',
        height: item.height ? `${item.height}cm` : '-cm',
        temperature: item.temperature ? `${item.temperature}°C` : '-°C',
        heart_rate: item.heart_rate ? `${item.heart_rate}lpm` : '-lpm',
        breathing_frequency: item.breathing_frequency ? `${item.breathing_frequency}rpm` : '-rpm',
        blood_glucose: item.blood_glucose ? `${item.blood_glucose}mg/dl` : '-mg/dl',
        o2_saturation: item.o2_saturation ? `${item.o2_saturation}%` : '-%',
        systolic_pressure: item.systolic_pressure ? `${item.systolic_pressure}mmHg` : '-mmHg',

      }
    })
    return temp
  }

  onSelectedRow = (item) => {
    //const {id} = this.props.match.params
    this.props.onAppointmentSelected(item)
    //this.props.history.push(`/patients/${id}/appointments/${item.id_appointment}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    const {vitals_signs} = this.props
    this.setState({vitals_signs:[...vitals_signs]})
  }

}

export default VitalSigns
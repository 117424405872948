export const formData = {
  additional_indications:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'additional_indications',
      type:'text',
      multiline:true,
      rows:5,
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}
import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid, fade } from '@material-ui/core';
import LongTextData from '../../../../components/DisplayData/LongTextData';
import ContentText from '../../../../components/Texts/ContentText';

class MainDiagnosisCard extends Component {
  render(){

    const {classes, diagnosis, onEdit} = this.props

    return(
      <CardWrapper
        title='Diagnósticos'
      >
        <div className={classes.root}>
          <div className={classes.container} >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='diagnosis_1'
                  label='Principal'
                  text={diagnosis ? diagnosis.diagnosis_1 : '-'}
                  onEdit={() => onEdit('diagnosis_1')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='diagnosis_2'
                  label='Secundario'
                  text={diagnosis ? diagnosis.diagnosis_2 : '-'}
                  onEdit={() => onEdit('diagnosis_2')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='diagnosis_3'
                  label='Terciario'
                  text={diagnosis ? diagnosis.diagnosis_3 : '-'}
                  onEdit={() => onEdit('diagnosis_3')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='diagnosis_4'
                  label='Cuaternario'
                  text={diagnosis ? diagnosis.diagnosis_4 : '-'}
                  onEdit={() => onEdit('diagnosis_4')}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </CardWrapper>
    )
  }

  onEditData = () => {
  }
}

const styles = theme => ({
  root:{padding:16},
  container:{
    //background: fade(theme.palette.primary.main,0.2),
    padding:8,
    borderRadius:8,
    minHeight:260
  }
})

export default withStyles(styles)(MainDiagnosisCard)
import React from 'react'
import { makeStyles } from '@material-ui/styles';
import ContentText from '../Texts/ContentText';
import { Grid, fade, IconButton, Icon } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root:{},
  container:{
    background: fade(theme.palette.primary.main,0.2),
    padding:8,
    borderRadius:8,
    minHeight:100
  }
}))


const LongTextData = props => {

  const classes = useStyles()
  const {label, text, onEdit, id, height} = props

  return(
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
              <ContentText text={label ? label : '-'} medium variant='light'/>
            </Grid>
            {onEdit ? (
              <Grid item>
                <IconButton size='small' onClick={() => onEdit(id, text)}><Icon style={{fontSize:16}}>edit</Icon></IconButton>
              </Grid>
            ) : null}
          </Grid>
          
        </Grid>
        <Grid item xs={12}>
          <div className={classes.container} style={{minHeight:height}}>
            <ContentText text={text ? text : '-'} medium/>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LongTextData
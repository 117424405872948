export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Citas',
      navigation:[
        {name:'Citas',path:'/appointments'},
      ],
    },
    table:{
      nodata:'-',
      //add_button:{
      //  icon:'add',
      //  label:'Agregar'
      //},
      header:[
        {id:1, label:'Paciente', flex:1},
        {id:2, label:'Tipo', flex:1},
        {id:3, label:'Fecha', flex:1},
        {id:4, label:'Inicio', flex:1},
        {id:5, label:'Fin', flex:1},
      ],
      filter_list:[
        {id:1, label:'Todo', type:null},
        {id:2, label:'Próximas', type:null},
        {id:3, label:'Fecha', type:'date'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'data',
          keys:['patient_name'],
        },
        {
          type:'data',
          keys:['appointment_type'],
        },
        {
          type:'data',
          keys:['appointment_date'],
          icon:{
            name:'calendar_today'
          }
        },
        {
          type:'data',
          keys:['start_hour'],
          icon:{
            name:'access_time'
          }
        },
        {
          type:'data',
          keys:['end_hour'],
          icon:{
            name:'access_time'
          }
        },
      ]
    },
    
  }
}
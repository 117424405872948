import {mainServer} from '../../../variables/config'

export const request_appointment = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/appointment/doctor/me/${id}`)
  return response.data.data.appointment
}

export const request_edit_appointment = async(patient_id, appointment_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/appointment/${patient_id}/${appointment_id}`, data)
  let response
  response = await mainServer.instance.get(`/appointment/doctor/me/${appointment_id}`)
  return response.data.data.appointment
}



export const request_add_medication = async(patient_id, recipe_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post(`/recipe/${patient_id}/${recipe_id}/medication`, data)
  let response = await mainServer.instance.get(`/recipe/${patient_id}/${recipe_id}/medications`)
  return response.data.data.recipe_medications
}

export const request_delete_medication = async(patient_id, recipe_id, medication_id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/recipe/${patient_id}/${recipe_id}/medication/${medication_id}`)
  let response = await mainServer.instance.get(`/recipe/${patient_id}/${recipe_id}/medications`)
  return response.data.data.recipe_medications
}


export const request_edit_medication = async(patient_id, recipe_id, medication_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/recipe/${patient_id}/${recipe_id}/medication/${medication_id}`, data)
  let response = await mainServer.instance.get(`/recipe/${patient_id}/${recipe_id}/medications`)
  return response.data.data.recipe_medications
}




export const request_doctor = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/users/${id}`)
  return response.data.data.user_found
}

export const request_doctor_details = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/doctor/me/details`)
  return response.data.data.doctor_details
}




export const request_diagnosis = async(patient_id, appointment_id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/diagnosis/appointment/${patient_id}/${appointment_id}`)
  return response.data.data.user_diagnosis
}

export const request_edit_diagnosis = async(patient_id, diagnosis_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/diagnosis/${patient_id}/${diagnosis_id}`, data)
  let response
  response = await mainServer.instance.get(`/diagnosis/${patient_id}/${diagnosis_id}`)
  return response.data.data.user_diagnosis
}




export const request_recipe = async(patient_id, appointment_id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/recipe/${patient_id}/appointment/${appointment_id}`)
  return response.data.data.recipe
}

export const request_edit_recipe = async(patient_id, appointment_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/recipe/${patient_id}/${appointment_id}`, data)
  let response
  response = await mainServer.instance.get(`/recipe/${patient_id}/${appointment_id}`)
  return response.data.data.recipe
}

export const request_recipe_medications = async(patient_id, recipe_id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/recipe/${patient_id}/${recipe_id}/medications`)
  return response.data.data.recipe_medications
}
import {mainServer} from '../../../variables/config'

export const request_me = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/users/me")
  return response.data.data.user_found
}

export const request_doctor_details = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/me/details")
  return response.data.data.doctor_details
}

export const request_doctor_translations = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/me/translations/1")
  return response.data.data.doctor_details_translation
}

export const request_doctor_addresss = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/address/address/main/me")
  return response.data.data.main_address
}

export const request_doctor_slots = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/me/slots")
  return response.data.data.doctor_slots
}

export const request_doctor_next_appointment = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/appointment/doctor/me/next")
  return response.data.data.doctor_appointment
}

import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import {formData} from './data'
import {contentData} from './content'

import ShadowInputText from '../../../../../components/Inputs/ShadowInputText/ShadowInputText'
import ShadowInputSelect from '../../../../../components/Inputs/ShadowInputSelect/ShadowInputSelect'
import { SecondRoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import ContentText from '../../../../../components/Texts/ContentText';
import { red } from '@material-ui/core/colors';

const filter_form = [  
  ['tobacco_condition_id','alcohol_condition_id','home_type_id','home_services','home_pets','inmunization_card'],
]

class PatientHistoryForm extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    selected:0,
  }

  render(){

    const {onCancel, language, isloading, error} = this.props
    const {formData, selected} = this.state

    const content = contentData[language]

    let formContent = null

    formContent = Object.keys(formData).map((item,key)=>{

      if(filter_form[selected].find(el => el === item)){
        let inputContent = null
        switch (formData[item].config.type) {
          case 'select':
            inputContent = (
              <ShadowInputSelect 
                data={formData[item]}
                onChange={this.onInputChange}/>
            )
            break;
          
          default:
            inputContent = (
              <ShadowInputText 
              data={formData[item]} 
              onChange={this.onInputChange}/>
            )
            break;
        }
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            {inputContent}
          </Grid>
        )
      }else{
        return null
      }
      
    })

    let infoContent = null

    if(isloading){
      infoContent = <SimpleLoader />
    }else{
      if(error){
        infoContent = <ContentText text={error} medium color={red[700]}/>
      }
    }

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} >
                    <ShadowInputText 
                      data={formData.current_conditions} 
                      onChange={this.onInputChange}/>
                  </Grid>
                  <Grid item xs={12} >
                    <ShadowInputText 
                      data={formData.pathological_history} 
                      onChange={this.onInputChange}/>
                  </Grid>
                  <Grid item xs={12} >
                    <ShadowInputText 
                      data={formData.family_history} 
                      onChange={this.onInputChange}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  {formContent}
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justify='flex-end' alignItems='center'>
              <Grid item>
                {infoContent}
              </Grid>
              <Grid item>
                <SecondRoundedButton label='Cancelar' size='small' color='grey' onClick={onCancel}/>
              </Grid>
              <Grid item>
                <SecondRoundedButton label='Guardar' size='small' onClick={this.onSubmit}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
  }

  componentDidMount(){
    const {patient_history, language, catalogs} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}
    //Setting catalogs
    temp['tobacco_condition_id'].options = catalogs['tobacco_conditions'] ? [...catalogs['tobacco_conditions']] : []
    temp['alcohol_condition_id'].options = catalogs['alcohol_conditions'] ? [...catalogs['alcohol_conditions']] : []
    temp['home_type_id'].options = catalogs['home_types'] ? [...catalogs['home_types']] : []


    Object.keys(formData).forEach((item) => {
      if(patient_history){
        let value = patient_history[item];
        temp[item].value = value ? value : '';
        temp[item].isVisited = value ? true : false;
        temp[item].isValid = value ? true : false;
      } // update data
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    
    this.setState({formData:{...temp}})
  }

  onChangeTab = (id) => {
    this.setState({selected:id})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, patient_history} = this.props;
    const {formData} = this.state;
    let temp = {...formData}

    console.log('Subir data')

    let isFormGood = true
    Object.keys(formData).forEach(item => {
      if(temp[item].isRequired){
        if(!temp[item].isValid){
          console.log(temp[item])
          isFormGood = false
          temp[item].isVisited = true
        }
      }
    })
    console.log(isFormGood)
    if(isFormGood){
      let data2Send = {};
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(patient_history){
            if(formData[item].value !== patient_history[item]){
              data2Send = {...data2Send, [item]:formData[item].value}
            }
          }else{
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
      onSubmit(data2Send)
    }else{
      this.setState({formData:{...temp}})
    }
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(PatientHistoryForm)
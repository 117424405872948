export const formData = {
  speciality_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'speciality_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  sub_speciality_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'sub_speciality_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  license:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'license',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  ssa:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'ssa',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  street:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'street',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  ext:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'ext',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  int:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'int',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  suburb:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'suburb',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  city:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'city',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  state:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'state',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  zip:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'zip',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  
}
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    title:'Mis horarios',
    layout:{
      title:'Editar horario',
      navigation:[
        {name:'Mi perfil',path:'/me'},
        {name:'Editar horario',path:'/me/edit-schedule'},
      ]
    },
    
  }
}
import React, {Component} from 'react'
import { withStyles, TableRow, TableCell, Table, TableBody, TableHead, Grid } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';
import GenericCell from './GenericCell';
import { palette } from '../../../variables/config';
import CustomPagination from './CustomPagination';


class TableContent extends Component {
  render(){

    const {classes, header, data, config, nodata, footer_config, onChangePageNumber, onChangePage, onSelectedRow} = this.props

    return(
      
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Table classes={{
                root:classes.table}} className={classes.rootTable}>
                <TableHead>
                  <TableRow>
                    {header.map((item,key)=>{
                      return(
                        <TableCell 
                          className={classes.cell_row}
                          padding={item.flex ? null : 'checkbox'} 
                          key={key.toString()}>
                          <ContentText text={item.label} medium color={palette.primary.main}/>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  
                </TableHead>
                <TableBody>
                  {data.map((item,key)=>{
                    return <TableRow className={classes.row} key={key.toString()} onClick={() => onSelectedRow(item)} >
                        {config.map((el,id)=>{
                          return (
                            <TableCell 
                              padding={el.flex ? 'default' : 'checkbox'} 
                              className={classes.cell} 
                              style={{
                                background: key%2 === 0 ? '#f1f5f8' : '#f1f5f8',
                                borderTopLeftRadius: id === 0 ? 8 : 0,
                                borderBottomLeftRadius: id === 0 ? 8 : 0,
                                borderTopRightRadius: id === config.length-1 ? 8 : 0,
                                borderBottomRightRadius: id === config.length-1 ? 8 : 0,
                              }}
                              key={id.toString()}>                        
                                  <GenericCell 
                                    avatar={el.image_key ? item[el.image_key] : null}
                                    config={el}
                                    values={this.onGetValues(item, el.keys)}
                                    nodata={nodata}
                                  />
                                
                            </TableCell>
                                
                          )
                        })}
                      
                    </TableRow>
                  })}
                </TableBody>
              </Table>
              
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <CustomPagination config={footer_config} onChangePageNumber={onChangePageNumber} onChangePage={onChangePage}/>
            </div>
          </Grid>
        </Grid>
          
          
    )
  }

  onGetValues = (data, keys) => {
    let response = []
    if(data){
      response = keys.map(item => {
        return data[item]
      })
    }
    return response
  }

}





const styles = theme => ({
  root:{
    overflowX: 'auto',
  },
  rootTable:{
    minWidth:800,
  },
  table:{
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
  },

  header:{
    flexGrow:1,
  },
  headcell:{
    paddingLeft:16, 
    paddingRight:16
  },
  cell_body:{
    paddingLeft:16, 
    paddingRight:16,
  },
  cell:{
    padding:16,
    border:0
  },
  cell_row:{
    padding:16,
    border:0
  },
  container:{
    background:'#f1f5f8',
  },
  row:{
    position:'relative',
    marginTop:16,
    marginBottom:16,
    borderRadius:8,
    paddingTop:16,
    paddingBottom:16,
    //background:'#f1f5f8',
    '&:hover':{
      cursor:'pointer'
    },
    //display:'flex',
    //flexDirection:'row',
    //flexGrow:1,
  },
  row_fixed:{
    position:'absolute',
    width:'100%',
    background:'#f1f5f8',
    height:'100%',
    top:-0,
    borderRadius:8,
    zIndex:0
  }
})

export default withStyles(styles)(TableContent)
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {contentData} from './content'
import { Grid } from '@material-ui/core';
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { palette } from '../../../../../variables/config';
import Subtitle from '../../../../../components/Texts/Subtitle';
import { SecondRoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import DocumentTable from '../../../../../components/Tables/DocumentTable';
import { cleanDocumentData } from './customFunctions';

class PatientDocuments extends Component {
  
  render(){

    const {classes, language, catalogs, documents, onDownloadFile, onUploadFile, onAddDocument, onFileSelected, onDelete} = this.props
    const content = contentData[language]

    const documentData = cleanDocumentData(documents)
    
    const banner = (
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
        >
          <Grid item xs>
            <Subtitle text={'Documentos de laboratorio'} medium color={palette.primary.main}/>
          </Grid>
          <Grid item>
            <SecondRoundedButton
              label={'Agregar'} 
              icon={'add'}
              color='primary' 
              size={'small'} 
              onClick={onAddDocument}
            />
          </Grid>
        </Grid>
      </Grid>
    )

    return(
        <CardWrapper>
          <div className={classes.root}>
              <Grid 
                container 
                spacing={4}
              >
                {banner}
                <Grid item xs={12} className={classes.horizontalLine}></Grid>
                <Grid item xs={12}>
                  <DocumentTable
                    header={content.header}
                    data={documentData}
                    icon={content.documents.icon}
                    nodata={content.documents.noDataTable}
                    permissions={content.documents.permissions}
                    onEdit={onFileSelected}
                    onUpload={onUploadFile}
                    onDownload={onDownloadFile}
                    onDelete={onDelete}
                  />
                </Grid>
              </Grid>
          </div>
        </CardWrapper>
    )
  }

  onReturn = () => {
    this.props.history.push('/history')
  }

  showView = (view) => {
    this.setState({view: view})
  }

}

const styles = (theme) => ({
  root: {
    position:'relative',
    padding:32
  },
  horizontalLine:{
    width:'100%',
    borderTopWidth:'thin',
    borderTopStyle:'dashed',
    borderColor:'lightgray'
  },
  rootTabs: {
    flexGrow: 1,
    display: 'flex',
  },
  tabpanelSelected:{
    borderRadius: '0px 20px 20px 0px',
    backgroundColor: theme.palette.primary.main,
  },
  tabSelected:{
    borderRadius: '20px 0px 0px 20px',
    backgroundColor: theme.palette.primary.main
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
  },
  badge:{
    width:'15px'
  },
  fabicon:{
    width: 34,
    height:34
  }
})

export default withStyles(styles)(PatientDocuments)
import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid } from '@material-ui/core';
import { palette } from '../../../../variables/config'; 
import VerticalDataWrapper from '../../../../components/Texts/VerticalDataWrapper';
import { SecondRoundedButton } from '../../../../components/Buttons/GeneralButtons';
import moment from 'moment'
import LongTextData from '../../../../components/DisplayData/LongTextData';

class AppointmentCard extends Component {
  render(){

    const {classes, appointment, onEdit} = this.props
    let appointment_data = this.onUpdateData(content.form, appointment)

    return(
      <CardWrapper
        title='Resumen'
      >
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {Object.keys(appointment_data).map((item,key)=>{
                  return(
                    <Grid item xs={12} md={item.md ? item.md : 6} key={key.toString()}>
                      <VerticalDataWrapper 
                        label={appointment_data[item].label} 
                        text={appointment_data[item].value}
                        icon={appointment_data[item].icon}
                        iconColor={appointment_data[item].iconColor}
                      />
                    </Grid>
                  )
                })}
                <Grid item xs={12}>
                  <LongTextData 
                    id='note'
                    label='Motivo'
                    text={appointment.note}
                    onEdit={onEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  onEditData = (id, value) => {
    console.log(id)
    console.log(value)
  }

  onUpdateData = (form, data) => {
    const {translations} = this.props
    let temp = {...form}
    Object.keys(form).forEach(item => {
      if(data){
        switch(form[item].type){
          case 'full_date':
            const aux = moment(data[item])
            temp[item].value = aux.isValid() ? `${aux.format('DD/MM/YYYY HH:mm')}hrs` : null
            break;
          case 'translate':
            temp[item].value = data[item] ? translations[data[item]] : null
            break;
          default:
            temp[item].value = data[item]
            break
        }
      }
    })
    return temp
  }
}

const styles = theme => ({
  root:{padding:24}
})

export default withStyles(styles)(AppointmentCard)

const content = {
  go2metting:'Ir a cita',
  form:{
    patient_name:{
      label:'Paciente',
      icon:'person',
      iconColor:palette.primary.main,
    },
    start_date:{
      label:'Inicio',
      icon:'calendar_today',
      iconColor:palette.primary.main,
      type:'full_date'
    },
    doctor_name:{
      label:'Doctor',
      icon:'calendar_today',
      iconColor:palette.primary.main,
    },
    license:{
      label:'Cédula profesional',
      iconColor:palette.primary.main,
    },
  }
}
import React, { Component } from 'react';
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Caption from '../../../../../components/Texts/Caption';

import moment from 'moment'
import ReactSVG from 'react-svg';
import { palette } from '../../../../../variables/config';
import Subtitle from '../../../../../components/Texts/Subtitle';

//import BloodPressure from '../../../../assets/medicalicons/BloodPressure';

class VitalSignCard extends Component {
  render(){

    const {data, classes} = this.props

    const datetime = moment(data.timestamp).format('DD/MM/YYYY, HH:mm')

    return(
      <CardWrapper
      >
        <div className={classes.root}>
          <Grid container alignItems='center'>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <Subtitle text={data.name} medium color={palette.primary.main}/>
                </Grid>
                <Grid item xs={12}>
                  <Caption text={datetime} variant='light'/>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography className={classes.value}>{data.value}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.unit}>{data.unit}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ReactSVG 
                src={data.image} 
                className={classes.icon} 
                beforeInjection={svg => {svg.setAttribute('fill', palette.primary.main)}}/>
            </Grid>
          </Grid>
        </div>
        
      </CardWrapper>
    )
  }
}

const styles = theme => ({
  root:{
    padding:'16px 32px'
  },
  value:{
    fontSize:24,
    fontWeight:500
  },
  unit:{
    fontSize:14,
    fontWeight:500,
    paddingTop:4
  },
  icon:{
    width:36,
    height:36
  }
})

export default withStyles(styles)(VitalSignCard)
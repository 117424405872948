import React from 'react'
import LoaderIcon from 'react-loader-spinner'
import { palette } from '../../variables/config';

const SimpleLoader = (props) => {
  return(
    <LoaderIcon 
        type="ThreeDots" 
        color={palette.primary.main}
        height={20} 
        width={50}
      />
  )
}

export default SimpleLoader
import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import BasicView from '../../../components/Layouts/BasicView/BasicView';

import {contentData} from './content'
import WeekScheduler from '../../../components/Calendars/WeekScheduler/WeekScheduler';
import {applicationData} from './data'
import ContentText from '../../../components/Texts/ContentText';
import { request_doctor_slots, request_add_slots, request_delete_slots } from './request';
import SimpleLoader from '../../../components/Loaders/SimpleLoader';

class EditDoctorSchedule extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    doctor_slots:[],
    cells: [...applicationData.cells],
    modals:{
      password_modal:{open:false,issending:false,error:null},
      image_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language} = this.props
    const {isloading, doctor_slots, issending} = this.state
    const content = contentData[language]

    let temp = doctor_slots.length > 0 ? [...doctor_slots] : [...applicationData.cells]
    const timeslots = JSON.parse(JSON.stringify([...applicationData.time_slots]))

    return(
      <BasicView
        isloading={isloading}
        content={content.layout}
        onReturn={this.onReturn}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <ContentText text={'Seleccione de favor los horarios acorde a su disponibilidad'} medium/>
                </Grid>
                {issending ? <Grid item>
                  <SimpleLoader /> </Grid> : null}
                
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.root}>
                <WeekScheduler cells={temp} timeslots={timeslots} onChange={this.onChange}/>
              </div>
            </Grid>
          </Grid>
        </div>
      </BasicView>
    )
  }
  
  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    try {
      let response = await request_doctor_slots()
      //console.log(response)
      let temp = this.onCleanSlots2Cell(response)
      this.setState({doctor_slots:[...temp]})
    } catch (error) {
      console.log(error)
    }
  }

  onCleanSlots2Cell = (doctor_slots) => {
    //console.log(doctor_slots)
    let temp = JSON.parse(JSON.stringify([...applicationData.cells]))
    const timeslots = JSON.parse(JSON.stringify([...applicationData.time_slots]))
    if(doctor_slots){
      doctor_slots.forEach((item,id)=>{
        //console.log(item)
        const start_date = item.start_hour.substring(0,5)
        const end_date = item.end_hour.substring(0,5)
        let start_index = -1;
        let end_index = -1;
        timeslots.forEach((el,key) => {
          if(el === start_date){
            start_index = key
          }
          if(el === end_date){
            end_index = key
          }
        })

        // Check if it is a valid slot
        if(start_index !== -1 && end_index!==-1){
          for(let i=start_index; i<end_index; i++){
            temp[i][item.weekday+1] = true
          }
        }
        
      })
    }
    return temp
  }

  onUpdateSchedules = async(data2add, data2delete) => {
    
    this.setState({issending:true})
    try {
      // Slot to add in doctor's schedules
      let response
      if(data2delete){
        if(data2delete.length > 0){
          
          await request_delete_slots({slots:data2delete})          
        }
      }
      if(data2add){
        if(data2add.length > 0){
          await request_add_slots({slots:data2add})          
        }
      }
      response = await request_doctor_slots()
      let temp = this.onCleanSlots2Cell(response)
      this.setState({doctor_slots:[...temp]})
    } catch (error) {
      
    }
    this.setState({issending:false})
  }

  
  onChange = (cells) => {

    const {doctor_slots} = this.state

    let times = [
      {day:'Su',slots:[]},
      {day:'M',slots:[]},
      {day:'Tu',slots:[]},
      {day:'W',slots:[]},
      {day:'Th',slots:[]},
      {day:'F',slots:[]},
      {day:'Sa',slots:[]},
    ]

    let old_schedule = [...doctor_slots]
    let new_schedule= [...cells]

    new_schedule.forEach((item,key)=>{
      item.forEach((el,id)=>{
        if(id > 0){
          if(el !== old_schedule[key][id]){
            times[id-1].slots.push({
              hours:applicationData.time_slots[key], 
              status:el,
              id: key})
          }
        }
      })
    })

    let items2add = []
    let items2delete = []

    times.forEach((item,key)=>{
      item.slots.forEach(el => {
        if(el.status){
          items2add.push({
            weekday:key,
            start_hour:el.hours,
            end_hour:el.id !== applicationData.time_slots.length-1 ? applicationData.time_slots[el.id+1] : applicationData.time_slots[0]
          })
        }else{
          items2delete.push({
            weekday:key,
            start_hour:el.hours,
            end_hour:el.id !== applicationData.time_slots.length-1 ? applicationData.time_slots[el.id+1] : applicationData.time_slots[0]
          })
        }
      })
      
    })

    this.onUpdateSchedules(items2add, items2delete)
  }



  onReturn = () => {
    this.props.history.push('/me')
  }
}

const styles = theme => ({
  root:{
    paddingBottom:32, 
  }
})

export default withStyles(styles)(EditDoctorSchedule)
import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import moment from 'moment'
import ShadowInputText from '../../Inputs/ShadowInputText/ShadowInputText';

class FilterForm extends Component {

  render(){

    const {filter} = this.props

    let formContent = null



    if(filter){
      switch(filter.type){
        case 'date':

          let selected_form =  {...forms_type['date']}
          selected_form.start_date.value = filter.value ? filter.value.start_date : selected_form.start_date.value
          selected_form.start_date.config.label = 'Seleccione una fecha'

          formContent = (
            <Grid container>
              <Grid item xs={12}>
                <ShadowInputText 
                  data={selected_form.start_date} 
                  onChange={this.onInputChange}/>
              </Grid>
            </Grid>
          )
          break
        default:
          break;
      }
    }

    return(
      <div>
        {formContent}
      </div>
    )
  }

  onInputChange = (data) => {
    const {filter, onChange} = this.props
    let temp = {...filter}
    if(filter){
      switch(filter.type){
        case 'date':
          if(data.isValid){
            temp.value = {
              start_date:data.value,
              end_date:moment(data.value).add(1,'days').format('YYYY-MM-DD')
            }
            onChange(temp)
          }
        break
        default:
          break
      }
    }
  }
}

const styles = theme => ({
  root:{}
})

const forms_type = {
  date:{
    start_date:{
      value: moment().format('YYYY-MM-DD'),
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'start_date',
        type:'date',
        fullWidth: true,
        icon:{
          name:'calendar_today'
        }
      },
      rules:{
        type:'date',
      }
    },
  }
}

export default  withStyles(styles)(FilterForm)
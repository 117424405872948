export const formData = {
  email:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'email',
      type:'text',
      fullWidth: true,
      icon:{name:'person', position:'start'}
    },
    rules:{
      type:'email',
    }
  },
  password:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
      icon:{name:'lock', position:'start'}
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
}
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Pacientes',
      navigation:[
        {name:'Pacientes',path:'/patients'},
      ],
    },
    table:{
      nodata:'Sin registro',
      header:[
        {id:1, label:'Nombre', flex:1},
        {id:4, label:'E-mail', flex:1,},
        {id:5, label:'Teléfono', flex:1,},
      ],
      filter_list:[
        {id:1, label:'Todo'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'avatar',
          image_key:'image',
          flex:1,
          keys:['patient_name'],
        },
        {
          type:'data',
          flex:1,
          keys:['patient_email'],
          icon:{
            name:'mail'
          }
        },
        {
          type:'data',
          flex:1,
          keys:['patient_mobile'],
          icon:{
            name:'phone'
          }
        },
      ]
    },
    
  }
}
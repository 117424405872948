export const contentData = {
  spanish:{
    form:{
      note:{
        label:'Describa el motivo',
        placeholder:'motivo',
        helper:'Motivo no válido'
      },
    }
  }
}
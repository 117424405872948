export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Pacientes',
      navigation:[
        {name:'Pacientes',path:'/patients'},
      ],
    },
    buttons:[
      {
        type:'button',
        icon:'add',
        label:'Nueva Cita'
      }
    ],
    table:{
      nodata:'-',
      
      header:[
        {id:1, label:'Fecha', flex:1},
        {id:2, label:'Peso', flex:1},
        {id:1, label:'Altura', flex:1},
        {id:2, label:'Temperatura', flex:1},
        {id:1, label:'F, cardiaca', flex:1},
        {id:2, label:'F. respiratoria', flex:1},
        {id:1, label:'Glucosa', flex:1},
        {id:2, label:'Sat. O', flex:1},
        {id:1, label:'P. sistólica', flex:1},

      ],
      filter_list:[
        {id:1, label:'Todo'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'data',
          keys:['addition_date'],
          flex:1,
        },
        {
          type:'data',
          keys:['weight'],
          flex:1,
        },
        {
          type:'data',
          keys:['height'],
          flex:1,
        },
        {
          type:'data',
          keys:['temperature'],
          flex:1,
        },
        {
          type:'data',
          keys:['heart_rate'],
          flex:1,
        },
        {
          type:'data',
          keys:['breathing_frequency'],
          flex:1,
        },
        {
          type:'data',
          keys:['blood_glucose'],
          flex:1,
        },
        {
          type:'data',
          keys:['o2_saturation'],
          flex:1,
        },
        {
          type:'data',
          keys:['systolic_pressure'],
          flex:1,
        },
        
        
      ]
    },
    
  }
}
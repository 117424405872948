import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles } from '@material-ui/styles';
import {applicationData} from './schedule_data'
import WeekScheduler from '../../../../components/Calendars/WeekScheduler/WeekScheduler';

class ScheduleCard extends Component {

  state = {
    cells: [...applicationData.cells]
  };

  render(){

    const {classes, disabled, onEdit, doctor_slots} = this.props
    const {cells} = this.state

    let temp = [...cells]
    const timeslots = [...applicationData.time_slots]
    if(doctor_slots){
      doctor_slots.forEach((item,id)=>{
        
        const start_date = item.start_hour.substring(0,5)
        const end_date = item.end_hour.substring(0,5)
        let start_index = -1;
        let end_index = -1;
        timeslots.forEach((el,key) => {
          if(el === start_date){
            start_index = key
          }
          if(el === end_date){
            end_index = key
          }
        })

        // Check if it is a valid slot
        if(start_index !== -1 && end_index!==-1){
          for(let i=start_index; i<end_index; i++){
            temp[i][item.weekday+1] = true
          }
        }
        
      })
    }

    return(
      <div>
        <CardWrapper
          nopadding
          title={content.title}
          link='Editar'
          onClickLink={onEdit}>
          <div className={classes.root}>
            <WeekScheduler disabled={!disabled} cells={temp} timeslots={timeslots} onChange={this.onChange}/>
          </div>
        </CardWrapper>
      </div>
    )
  }

  onChange = () => {

  }

  onCleanSlots2Cell = (doctor_slots) => {
    //console.log(doctor_slots)
    let temp = JSON.parse(JSON.stringify([...applicationData.cells]))
    const timeslots = JSON.parse(JSON.stringify([...applicationData.time_slots]))
    if(doctor_slots){
      doctor_slots.forEach((item,id)=>{
        //console.log(item)
        const start_date = item.start_hour.substring(0,5)
        const end_date = item.end_hour.substring(0,5)
        let start_index = -1;
        let end_index = -1;
        timeslots.forEach((el,key) => {
          if(el === start_date){
            start_index = key
          }
          if(el === end_date){
            end_index = key
          }
        })

        // Check if it is a valid slot
        if(start_index !== -1 && end_index!==-1){
          for(let i=start_index; i<end_index; i++){
            temp[i][item.weekday+1] = true
          }
        }
        
      })
    }
    return temp
  }
}

const styles = theme => ({
  root:{
    paddingBottom:32, 
    maxHeight:600, overflowY:'auto', overflowX:'hidden'
  }
})

export default withStyles(styles)(ScheduleCard)

const content = {
  title:'Mi horario'
}
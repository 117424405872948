import React from 'react'
import { Table, TableHead, TableBody, TableCell, TableRow, fade, IconButton, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames'
import ContentText from '../Texts/ContentText';

const useStyles = makeStyles(theme => ({
  root:{},
  table:{borderCollapse:'collapse'},
  cell_head:{
    padding:16,
    background:theme.palette.primary.main,
    color:'white'
  },
  cell_head_first:{
    borderTopLeftRadius:32,
  },
  cell_head_last:{
    borderTopRightRadius:32,
  },
  cell_body:{
    padding:16,
    borderBottom:`2px dashed ${grey[100]}`,
  },
  
  cell_inside_body:{
    padding:0,
    borderBottom:`2px dashed ${grey[100]}`,
  },
  cell_inside_body_last:{
    borderBottom:0,
  },
  cell_body_primary:{
    padding:16,
    background: fade(theme.palette.primary.main,0.2)
  },
  cell_body_primary_last:{
    borderBottomLeftRadius:32,
    borderBottom:0,
  },
  cell_body_inside_last:{
    border:0,
  }
}))


const VaccinationTable = props => {

  const {data, onEdit} = props
  const classes = useStyles()
  return(
    <Table classes={{root:classes.table}}>
      <TableHead>
        <TableRow>
          {data.header.map((item,key)=>{
            return(
              <TableCell className={cx({
                [classes.cell_head]:true,
                [classes.cell_head_first]:key===0,
                [classes.cell_head_last]:key===data.header.length-1,
              })} key={key.toString()} >{item.text}</TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.body.map((item,key)=>{
          return(
            <TableRow key={key.toString()}>
              <TableCell className={cx({
                [classes.cell_body_primary]:true,
                [classes.cell_body_primary_last]:key === data.body.length-1
              })}>
                <ContentText text={item.vaccine} medium variant='bold'/>
              
              </TableCell>
              <TableCell className={classes.cell_body}><ContentText text={item.illness} medium/></TableCell>

              <TableCell className={cx({
                [classes.cell_inside_body]:true,
                [classes.cell_inside_body_last]:key === data.body.length-1,
              })}>
                <Table classes={{root:classes.table}}>
                  <TableBody>
                    {item.data.map((el,id)=>{
                        return(
                          <TableRow key={id.toString()}>
                            <TableCell className={cx({
                              [classes.cell_body]:true,
                              [classes.cell_body_inside_last]:id === item.data.length-1,
                            })}><ContentText text={el.dose} medium/></TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                    
                </Table>
              </TableCell>
              <TableCell className={cx({
                [classes.cell_inside_body]:true,
                [classes.cell_inside_body_last]:key === data.body.length-1,
              })}>
                <Table classes={{root:classes.table}}>
                  <TableBody>
                  {item.data.map((el,id)=>{
                      return(
                        <TableRow key={id.toString()}>
                        <TableCell className={cx({
                              [classes.cell_body]:true,
                              [classes.cell_body_inside_last]:id === item.data.length-1,
                            })}><ContentText text={el.frequency} medium/></TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                    
                </Table>
              </TableCell>
              <TableCell className={cx({
                [classes.cell_inside_body]:true,
                [classes.cell_inside_body_last]:key === data.body.length-1,
              })}>
                <Table classes={{root:classes.table}}>
                  <TableBody>
                  {item.data.map((el,id)=>{

                    const date = el.data ? el.data : '-'

                      return(
                        <TableRow key={id.toString()}>
                          <TableCell className={cx({
                              [classes.cell_body]:true,
                              [classes.cell_body_inside_last]:id === item.data.length-1,
                            })}>
                              <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                  <ContentText text={date} medium/>
                                </Grid>
                                <Grid item>
                                  <IconButton size='small' onClick={() => onEdit({...el, vaccine:item.vaccine})}><Icon style={{fontSize:18}}>edit</Icon></IconButton>
                                </Grid>
                              </Grid>
                          </TableCell>
                        </TableRow>
                      )
                        
                    })}
                  </TableBody>
                    
                </Table>
              </TableCell>
            </TableRow>
          )
        })

        }
      </TableBody>
    </Table>
  )
}

export default VaccinationTable
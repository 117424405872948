import React from 'react'
import { makeStyles, Grid, Icon } from '@material-ui/core';
import ContentText from '../Texts/ContentText';
import Rating from '@material-ui/lab/Rating';


const useStyles = makeStyles(theme => ({
  root:{},
  icon:{
    color:theme.palette.primary.main
  }
}))

const EvaluationItem = props => {

  const classes = useStyles()
  const {data, evaluation, type} = props

  let contentData = null
  if(data){
    if(data.type === 'rating'){
      contentData = (
        <Grid item xs={12} md={6}>
          <Grid container justify='flex-end'>
            <Grid item>
              <Rating value={data ? data.value : 2.5} precision={0.2} readOnly/>
            </Grid>
          </Grid>
        </Grid>
      )
    }else{
      contentData = <Grid item xs={12} md={6}>
        <ContentText align='right' text={data.value ? data.value : '-'} medium/>
      </Grid>
    }
  }
  

  return(
    <div>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={6}>
          <Grid container wrap='nowrap' alignItems='center' spacing={1}>
            <Grid item>
              <Icon className={classes.icon}>{data ? data.icon : ''}</Icon>
            </Grid>
            <Grid item xs>
              <ContentText text={data ? data.label : '-'} medium/>
            </Grid>
          </Grid>
        </Grid>
        {contentData}
      </Grid>
    </div>
  )
}

export default EvaluationItem
import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ProfileAvatar from '../../../../components/Avatars/ProfileAvatar'
import VerticalDataWrapper from '../../../../components/Texts/VerticalDataWrapper';
import ContentText from '../../../../components/Texts/ContentText';
import { palette } from '../../../../variables/config';

const form_sections = {
  basic_info:['first_name','fathers_last_name','mothers_last_name','gender_text_id','email','mobile'],
  additional_data:['blood_type_text_id','birth_date','curp','fixed_phone','timezone']
}

const contentData = {
  spanish:{
    additional_data_title:'Datos adicionales',
    bio_title:'Biografía'
  }
}

class ProfileCard extends Component {
  render(){
    const {classes, user, doctor_translations, form, onEdit, language} = this.props

    let bio = ''
    if(doctor_translations){
      bio = doctor_translations.bio ? doctor_translations.bio : '-'
    }

    const content = contentData[language]
    return(
      <div>
        <CardWrapper
          title='Mi información'
          link='Editar'
          onClickLink={onEdit}
        >
          <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid item xs={12} >
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <Grid container justify='center'>
                      <Grid item><ProfileAvatar user={user} image={user ? user.image : null}/></Grid>
                    </Grid>
                    
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      {form_sections.basic_info.map((item,key)=>{
                        return(
                          <Grid item xs={12} md={6} key={key.toString()}>
                            <VerticalDataWrapper 
                              label={form[item].label} 
                              text={form[item].value}
                              icon={form[item].icon}
                              iconColor={form[item].iconColor}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContentText text={content.additional_data_title} medium color={palette.primary.main} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {form_sections.additional_data.map((item,key)=>{
                        return(
                          <Grid item xs={12} md={4} key={key.toString()}>
                            <VerticalDataWrapper 
                              label={form[item].label} 
                              text={form[item].value}
                              icon={form[item].icon}
                              iconColor={form[item].iconColor}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContentText text={content.bio_title} medium color={palette.primary.main} />
                  </Grid>
                  <Grid item xs={12}>
                    <ContentText text={bio} medium />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    padding:32
  }
})

export default withStyles(styles)(ProfileCard)
export const contentData = {
  spanish:{
    form:{
      additional_indications:{
        label:'Describa las indicaciones',
        placeholder:'Introduzca las indicaciones',
        helper:'Indicaciones no válidas'
      },
    }
  }
}
import React, { Component } from 'react';
import BasicView from '../../../components/Layouts/BasicView/BasicView';
import { contentData } from './content';
import { withStyles} from '@material-ui/core';
import WorkInfoForm from './components/WorkInfoForm/WorkInfoForm';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import {request_update_patient_history, request_delete_image, request_upload_image, request_doctor_addresss, request_doctor_details, request_update_doctor_details, request_update_doctor_address } from './requests';


class EditDoctorWork extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    address:null,
    work_data:null,
    modals:{
      password_modal:{open:false,issending:false,error:null},
      image_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language, catalogs} = this.props
    const {address, work_data, isloading, issending, error} = this.state

    const content = contentData[language]

    return(
      <BasicView
        isloading={isloading}
        content={content.layout}
        onReturn={this.onReturn}
      >
        <div>
          <CardWrapper>
            <div className={classes.root}>
              <WorkInfoForm 
                address={address}
                work_data={work_data}
                language={language} 
                catalogs={catalogs} 
                isloading={issending}
                error={error}
                onSubmit={this.onSubmit}
                onCancel={this.onReturn}/>
            </div>
          </CardWrapper>
        </div>

      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try {
      let response = await request_doctor_addresss()
      this.setState({address:{...response}})
      response = await request_doctor_details()
      this.setState({work_data:{...response}})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onChangeTab = (id) => {
    this.setState({selected:id})
  }

  onSubmit = async(workdata, addressdata) => {
    if(workdata || addressdata){
      this.setState({issending:true})
      try {
        
        if(JSON.stringify(workdata) !== "{}"){
          let response = await request_update_doctor_details(workdata)
          this.setState({work_data:{...response}})
        }

        if(JSON.stringify(addressdata) !== "{}"){
          let response = await request_update_doctor_address(addressdata)
          this.setState({address:{...response}})
        }
        
        this.setState({error:null, issending:null})
        this.props.history.push('/me')
      } catch (error) {
        if(error.response){
          this.setState({issending:false})
          let e = {...error.response.data.error}
          this.setState({error:`#${e.code}, ${e.message}`})
        }else{
          this.setState({error:`No Internet`})
        }
        
      }
    }
  }

  onChangePassword = async(data) => {
    const type = 'password_modal'
    
    if(data){
      if(JSON.stringify(data) !== "{}"){
        try {
          this.onEditModal(type,{issending:true})
          let response = await request_update_patient_history(data)
          this.setState({patient_history:{...response}})
          this.onCloseModal(type)
        } catch (error) {
          this.onEditModal(type,{issending:false})
          if(error.response){
            let e = {...error.response.data.error}
            this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
          }else{
            this.onEditModal(type,{error:`No Internet`})
          }
        }
      }
    }
  }


  onSubmitPicture = async(type, file) => {

    const type_modal = 'image_modal'

    try {
      this.onEditModal(type_modal,{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_upload_image(file[0], ext);
      this.setState({patient_history:{...response}})
      this.onCloseModal(type_modal)
    } catch (error) {
      this.onEditModal(type_modal,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type_modal,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type_modal,{error:`No Internet`})
      }
    }
  }

  onDeletePicture = async() => {
    const type = 'image_modal'
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_delete_image();
      this.setState({patient_history:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onReturn = () => {
    this.props.history.push('/me')
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }


}

const styles = theme => ({
  root:{
    padding:32
  },
  link:{
    cursor:'pointer'
  }
})

export default withStyles(styles)(EditDoctorWork)
import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../Cards/CardWrapper/CardWrapper';
import TableMenu from './componentes/TableMenu'
import SearchBar from './componentes/SearchBar'
import TableContent from './componentes/TableContent'
import FilterForm from './componentes/FilterForm';
import { grey } from '@material-ui/core/colors';
import { SecondRoundedButton } from '../Buttons/GeneralButtons';



class MasterTableA extends Component {
  render(){

    const {table, config, data, filter, onAdd, onChangePageNumber, onChangePage, onSearch, onSelectedRow, onChangeFilter, classes} = this.props
    //console.log(data)
    return(
      <CardWrapper>
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div style={{padding:16, boxSizing:'border-box'}}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <TableMenu selected={filter ? filter.id : 1} options={table.filter_list} onItemSelected={this.onFilterSelected}/>
                  </Grid>
                  <Grid item xs>

                  </Grid>
                  {onAdd ? (
                    <Grid item>
                    <SecondRoundedButton size='small' 
                        color='primary'
                        icon={'add'}
                        label={'Agregar'}
                        onClick={onAdd}/>
                  </Grid>
                  ) : null}
                  
                  <Grid item>
                    <FilterForm filter={filter} onChange={onChangeFilter}/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <SearchBar onSearch={onSearch}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.tableContainer}>
                <TableContent 
                  footer_config={config}
                  header={table.header} 
                  data={data} 
                  config={table.table_row} 
                  nodata={table.nodata}
                  onSelectedRow={onSelectedRow}
                  onChangePageNumber={onChangePageNumber}
                  onChangePage={onChangePage}/>
              </div>
              
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  onFilterValueChange = (filter) => {
    //onChangeFilter(filter)
  }

  onFilterSelected = (option) => {
    const {filter, onChangeFilter} = this.props
    let temp = {...filter}
    temp.id = option.id
    temp.value = null
    temp.type = option.type
    onChangeFilter(temp)
  }
}

const styles = theme => ({
  root:{},
  tableContainer:{
    marginTop:16,
    paddingTop:16,
    borderTop:`1px dashed ${grey[300]}`,
    paddingBottom:16,
    marginLeft:16,
    marginRight:16,
    boxSizing:'border-box',
  }
})

export default withStyles(styles)(MasterTableA)
import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid, Icon, fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ContentText from '../../../../../components/Texts/ContentText';
import moment from 'moment'
import cx from 'classnames'
import { amber, blueGrey } from '@material-ui/core/colors';


class VaccinationCard extends Component {

  render(){

    const {classes, language, data, title, onClick} = this.props
    const content = contentData[language]

    let vaccinationContent = null
    if(data){
      vaccinationContent = Object.keys(data).map((item,key)=>{
        return(
          <Grid item xs={12} key={key.toString()}>
            <VaccinationItem 
              iseven={key%2 === 0}
              text={data[item].label}
              date={data[item].date}/>
          </Grid>
        )
      })
    }

    return(
      <div>
        <CardWrapper
          title={title}
          link={content.edit}
          onClickLink={onClick}>
          <div className={classes.container}>
            
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <HeaderList title='Tipo' status='Fecha'/>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.subcontainer}>
                    <Grid container>
                      {vaccinationContent}   
                    </Grid>
                  </div> 
                </Grid>
                
              </Grid>
            
          </div>
        </CardWrapper>
      </div>
    )
  }
}

const contentData = {
  spanish:{
    title:'Próxima cita',
    edit:'Ver más'
  }
}

const styles = theme => ({
  root:{},
  container:{
    padding:24,
    paddingRight:16,
    paddingLeft:16,
    [theme.breakpoints.down('sm')]: {
      padding:16,
    },
    
  },
  subcontainer:{
    maxHeight:400,
    paddingRight:8,
    overflowY:'auto',
    overflowX:'hidden'
  }
})

export default withStyles(styles)(VaccinationCard)

const useStylesHeader = makeStyles(theme => ({
  root:{
    background:theme.palette.primary.main,
    padding:8,
    paddingRight:32,
    paddingTop:12,
    paddingBottom:12,
    borderRadius:4,
  }
}))

const HeaderList = props => {

  const {title, status} = props
  const classes = useStylesHeader()

  return(
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs>
          <ContentText text={title ? title : ''} medium color='white'/>
        </Grid>
        <Grid item>
          <ContentText text={status ? status : ''} medium color='white'/>
        </Grid>
      </Grid>
    </div>
  )
}



const useStyles = makeStyles(theme => ({
  root:{
    padding:8,
    borderRadius:4
  },
  rootModified:{
    background:fade(blueGrey[50],1)
  },
  error:{
    color:amber[700]
  },
  iconContainer:{
    marginTop:3,
  },
  button:{
  },
  info:{
    color:theme.palette.primary.main
  }
}))

const VaccinationItem = props => {

  const {text, date, iseven} = props
  const classes = useStyles()
  let status = false
  if(date){
    let temp = moment(date,'DD/MM/YYYY')
    if(temp.isValid()){
      status = true
    }
  }

  let iconContent = null

  if(status){
    iconContent = (
      <Grid container wrap='nowrap' >
        <Grid item>
          <Icon fontSize='small' className={classes.info}>calendar_today</Icon>
        </Grid>
        <Grid item>
          <ContentText text={date} medium/>
        </Grid>
      </Grid>
    )
  }else{
    iconContent = (
      <div className={classes.iconContainer}>
        <Icon className={classes.error} fontSize='small'>priority_high</Icon>
      </div>
    )
  }

  return(
    <div className={cx({
      [classes.root]:true,
      [classes.rootModified]:iseven
    })}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <ContentText text={text} medium />
        </Grid>
        <Grid item>
          {iconContent}
        </Grid>
      </Grid>
    </div>
    
  )
}
import logoSignIn from '../assets/logoTrionix.png'
import axios from 'axios';
import { blue } from '@material-ui/core/colors';

export const palette = {
  primary:{
    main:'#c73794'
  },
  secondary:{
    main:blue[500]
  }
}

export const calendar = {
  spanish:{
    short_months: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
  }
}

export const layout = {
  width:140,
}

export const getBatteryPercentage = (counts) => {
  return (-(0.609209*Math.pow(counts,5))+(88.276908*Math.pow(counts,4))-(5111.694563*Math.pow(counts,3))+(147848.193799*Math.pow(counts,2))-2135910.527044*counts+12329269.492005).toFixed(0)
}


export const drawerWidth = 145;
export const closedDrawerWidth = 0;

export const image_path = "https://txserver00001-trionixtech.pagekite.me/api"
export const imgServerS3 = "http://celudoc-dev-public.s3-website.us-east-2.amazonaws.com/";
export const rfcImgServerS3 = "https://s3.us-east-2.amazonaws.com/asiconnect3-public/business/image";

export const SignIn = {
  logo: logoSignIn,
  spinner: {
    color: '#0c519d'
  }
}

export const SideBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const TopBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const Footer = {

}

export const Languages = [
  {id:2, code:'MX', language:'spanish'},
  {id:1, code:'US', language:'english'},
];

export const mainServer = {
  instance: axios.create({
    baseURL: "https://dev.celudoc.com/api/v0.1_hc",
    headers: {'Content-Type': 'application/json'},
  }),
  credentials: {
    user: 'trionix',
    password: 'qnJVws4V6amWvZS6f25DJC7cjmLpQzBz'
  }
}

export const s3Server = {
  instance: axios.create({
    baseURL: "https://s3.us-east-2.amazonaws.com/asiconnect3-public/user/image",
    headers: {'Access-Control-Allow-Origin': '*'},
  }),
}

export const conekta = {
  initConekta: () => {
    const script = document.createElement('script')
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
    script.async = true
    document.body.appendChild(script)
    window.Conekta.setPublicKey('key_LqhGpfV61VBEPyExzv1sz7w')
  },
  setLanguage: (language) => {
    window.Conekta.setLanguage(language === 'spanish' ? "es" : "en");
  },
  getLanguage: () => {
    window.Conekta.getLanguage();
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (tokenParams, successCallback, errorCallback) => {
    return window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
  }
}

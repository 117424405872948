export const contentData = {
  spanish:{
    form:{
      diagnosis:{
        label:'',
        placeholder:'Introduzca la descripción',
        helper:'Dato no válido'
      },
    }
  }
}
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Agregar paciente',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Agregar',path:'/patients/add'},
      ]
    },
    password_modal:{
      title:'Cambio de contraseña',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    }
    
  }
}
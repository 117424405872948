import {grey} from '@material-ui/core/colors';


const styles = theme => ({
  root:{
    overflow:'hidden'
  },
  subroot:{
    position:'relative', height:'100vh', overflowY:'auto', backgroundColor: theme.palette.primary.dark, boxSizing:'border-box'
  },
  cardContainer:{
    position:'relative',
  },
  card:{
    position:'absolute',
    zIndex:3,
    background:'white',
    boxSizing:'border-box',
    borderRadius:32,
    width:400,
    minHeight:500,
    top:'50%',
    left:'50%',
    marginLeft:-175,
    marginTop:-225,
    [theme.breakpoints.down('sm')]: {
      width:350,
    },
  },
  
  logoContainer:{
    position:'absolute',
    padding:10,
    boxSizing:'border-box',
    borderRadius:'50%',
    background:'white',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    border:`2px solid ${grey[100]}`,
    top:-34,
    left:'50%',
    marginLeft:-34 
  },
  logo:{
    width:58,
    height:58,
  },
  container:{
    boxSizing:'border-box',
    padding:32,
    paddingTop:64,
    [theme.breakpoints.down('sm')]: {
      paddingLeft:16,
      paddingRight:16
    },
  },
  trionix:{
    textTransform:'uppercase',
    fontFamily:'Audiowide',
    fontWeight:'600',
    color:theme.palette.primary.main,
    letterSpacing:6,
    fontSize:28
  },
  title:{
    color:theme.palette.secondary.main,
    fontWeight:'600'
  },
  subtitle:{
    fontWeight:'600',
    color:grey[300],
    marginTop:16
  },
  button:{
    background:theme.palette.primary.main,
    color:'white',
    borderRadius:32,
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  },
  brand:{
    width:140,
    margin:'auto'
  },
  brandContainer:{
    padding:16
  }


  
});

export default styles;

export const contentData = {
  spanish:{
    form:{
      title:{
        label:'Título',
        placeholder:'Título',
        helper:'Título no válido'
      },
      description:{
        label:'Descripción',
        placeholder:'Descripción (opcional)',
        helper:'Descripción no válida'
      },
      filename:{
        label:'Documento'
      }
    },
    button:'Agregar',
    cancel:'Cancelar',
    fileDropMessage: 'Arrastre o de clic para subir un archivo',
    subtitle:'Nombre: '

  }
}
export const contentData = {
  spanish:{
    form:{
      weight:{
        label:'Peso (kg)',
        placeholder:'Peso (kg)',
        helper:'No válido'
      },
      height:{
        label:'Altura (cm)',
        placeholder:'Altura (cm)',
        helper:'No válido'
      },
      temperature:{
        label:'Temperatura (°C)',
        placeholder:'Temperatura (°C)',
        helper:'No válido'
      },
      heart_rate:{
        label:'Ritmo cardiaco (lpm)',
        placeholder:'Ritmo cardiaco (lpm)',
        helper:'No válido'
      },
      breathing_frequency:{
        label:'Frecuencia respiratoria (rpm)',
        placeholder:'Fecuencia respiratoria (rpm)',
        helper:'No válido'
      },
      blood_glucose:{
        label:'Glucosa (mg/dl)',
        placeholder:'Glucosa (mg/dl)',
        helper:'No válido'
      },
      o2_saturation:{
        label:'Sat. O (%)',
        placeholder:'Sat. O (%)',
        helper:'No válido'
      },
      systolic_pressure:{
        label:'P. sistólica (mmHg)',
        placeholder:'P. sistólica (mmHg)',
        helper:'No válido'
      },
      
    }
  }
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import { Icon, Fab } from '@material-ui/core';
import { request_add_vital_signs, request_upload_document, request_download_document, request_upload_image, request_download_image, request_add_appointment, request_doctor_appointments, request_delete_image , request_edit_image, request_add_image, request_images, request_delete_document, request_edit_document, request_add_document, request_documents, request_signal_vitals, request_patient, request_add_employee_record, request_patient_history, request_infant_vaccination, request_adult_vaccination } from './request';
import moment from 'moment'
import GeneralView from '../views/GeneralView/GeneralView';
import PatientDocuments from '../views/PatientDocuments/PatientDocuments/PatientDocuments';
import FileManagerModal from '../../../components/Modals/FileManagerModal/FileManagerModal';
import DocumentModal from '../views/PatientDocuments/PatientDocuments/modals/DocumentModal/DocumentModal';
import EditDocumentModal from '../views/PatientDocuments/PatientDocuments/modals/EditDocumentModal/EditDocumentModal';
import WarningModal from '../../../components/Modals/WarningModal';
import PatientImages from '../views/PatientImages/PatientImages/PatientImages';
import ImageModal from '../views/PatientImages/PatientImages/modals/ImageModal/ImageModal';
import EditImageModal from '../views/PatientImages/PatientImages/modals/EditImageModal/EditImageModal';
import PatientAppointments from '../views/Appointments/PatientAppointments';
import AddAppointmentModal from '../views/Appointments/modals/AddAppointmentModal/AddAppointmentModal';
import UploadImageModal from '../views/PatientImages/PatientImages/modals/UploadImageModal/UploadImageModal';
import UploadDocumentModal from '../views/PatientDocuments/PatientDocuments/modals/UploadDocumentModal/UploadDocumentModal';
import VitalSigns from '../views/VitalSigns/VitalSigns';
import AddVitalSignsModal from '../views/VitalSigns/modals/AddVitalSignsModal/AddVitalSignsModal';



class PatientView extends Component {

  state = {
    isloading:false,
    selected:1,
    user:null,
    documents:[],
    images:[],
    patient_history:null,
    adult_vaccination:null,
    infant_vaccination:null,
    vitals_signs:[],
    document_selected:null,
    image_selected:null,
    appointments:[],  
    user_vital_signs:[],
    modals:{
      record_modal:{open:false,issending:false,error:null},
      add_document_modal:{open:false,issending:false,error:null},
      upload_document_modal:{open:false,issending:false,error:null},
      edit_document_modal:{open:false,issending:false,error:null},
      delete_document_modal:{open:false,issending:false,error:null},
      add_image_modal:{open:false,issending:false,error:null},
      upload_image_modal:{open:false,issending:false,error:null},
      edit_image_modal:{open:false,issending:false,error:null},
      delete_image_modal:{open:false,issending:false,error:null},
      add_appointment_modal:{open:false,issending:false,error:null},
      add_vital_signs_modal:{open:false,issending:false,error:null},
    },
  }
  
  render(){

    const {classes,language, catalogs, translations, match, history} = this.props
    const {appointments, modals, documents, images, image_selected, document_selected, user, patient_history, vitals_signs, isloading, infant_vaccination, adult_vaccination, selected} = this.state
    const content = contentData[language]

    //console.log(appointments)

    let contentView = null
    const {id} = match.params

    switch(selected){
      case 1:
        contentView = (
          <GeneralView 
            patient_id={id}
            history={history}
            language={language}
            catalogs={catalogs}
            translations={translations}
            user={user} 
            vitals_signs={vitals_signs}
            patient_history={patient_history}
            infant_vaccination={infant_vaccination}
            adult_vaccination={adult_vaccination}/>
        )
        break
      case 2:
        contentView = (
          <VitalSigns 
            patient_id={id}
            history={history}
            translations={translations}
            vitals_signs={vitals_signs}
            language={language}
            catalogs={catalogs}
            onAdd={() => this.onOpenModal('add_vital_signs_modal')}
            onAppointmentSelected={this.onAppointmentSelected}/>
        )
        break;
      case 3:
        contentView = (
          <PatientAppointments 
            patient_id={id}
            history={history}
            translations={translations}
            appointments={appointments}
            language={language}
            catalogs={catalogs}
            onAppointmentSelected={this.onAppointmentSelected}/>
        )
        break;
      case 5:
          contentView = (
            <PatientImages 
              patient_id={id}
              documents={images}
              history={history}
              language={language}
              catalogs={catalogs}
              translations={translations}
              user={user} 
              onUploadFile={this.onPreUploadImage}
              onDownloadFile={this.onDownloadImage}
              onAddDocument={this.onPreAddImage}
              onFileSelected={this.onImageSelected}
              onDelete={this.onPreDeleteImage}/>
          )
          break;
      case 6:
          contentView = (
            <PatientDocuments 
              patient_id={id}
              documents={documents}
              history={history}
              language={language}
              catalogs={catalogs}
              translations={translations}
              user={user} 
              onUploadFile={this.onPreUploadDocument}
              onDownloadFile={this.onDownloadDocument}
              onAddDocument={this.onPreAddDocument}
              onFileSelected={this.onDocumentSelected}
              onDelete={this.onPreDeleteDocument}/>
          )
          break;
      default:
        break
    }


    ///////////////////***************** MODAL SECTION ******************//////////////////
    let modalcontent = null

    if(modals.add_appointment_modal.open){
      modalcontent = (
        <AddAppointmentModal
          data={modals.add_appointment_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('add_appointment_modal')}
          onSubmit={this.onAddAppointment}
        />
      )
    }

    if(modals.add_vital_signs_modal.open){
      modalcontent = (
        <AddVitalSignsModal
          data={modals.add_vital_signs_modal}
          content={content.add_vital_signs_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('add_vital_signs_modal')}
          onSubmit={this.onAddVitalSigns}
        />
      )
    }

   ////////////////////// DOCUMENT MODALS /////////////////////////////////


    if(modals.add_document_modal.open){
      modalcontent = (
        <DocumentModal
          data={modals.add_document_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('add_document_modal')}
          onSubmit={this.onAddDocument}
        />
      )
    }

    if(modals.upload_document_modal.open){
      modalcontent = (
        <UploadDocumentModal
          data={modals.upload_document_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('upload_document_modal')}
          onSubmit={this.onUploadDocument}
        />
      )
    }

    if(modals.edit_document_modal.open){
      modalcontent = (
        <EditDocumentModal
          origin_data={document_selected}
          data={modals.edit_document_modal}
          content={content.edit_document_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('edit_document_modal')}
          onSubmit={this.onEditDocument}
        />
      )
    }

    if(modals.delete_document_modal.open){
      modalcontent=(
        <WarningModal 
          data={modals.delete_document_modal}
          content={content.delete_document_modal}
          onClose={() => this.onCloseModal('delete_document_modal')}
          onDelete={this.onDeleteDocument}
        />
      )
    }


    ////////////////////// IMAGE MODALS /////////////////////////////////

    if(modals.add_image_modal.open){
      modalcontent = (
        <ImageModal
          data={modals.add_image_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('add_image_modal')}
          onSubmit={this.onAddImage}
        />
      )
    }

    if(modals.upload_image_modal.open){
      modalcontent = (
        <UploadImageModal
          data={modals.upload_image_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('upload_image_modal')}
          onSubmit={this.onUploadImage}
        />
      )
    }

    if(modals.edit_image_modal.open){
      modalcontent = (
        <EditImageModal
          origin_data={image_selected}
          data={modals.edit_image_modal}
          content={content.edit_image_modal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('edit_image_modal')}
          onSubmit={this.onEditImage}
        />
      )
    }

    if(modals.delete_image_modal.open){
      modalcontent=(
        <WarningModal 
          data={modals.delete_image_modal}
          content={content.delete_document_modal}
          onClose={() => this.onCloseModal('delete_image_modal')}
          onDelete={this.onDeleteImage}
        />
      )
    }

    

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
        selected={selected}
        onChange={this.onChangeView}

        onReturn={this.onReturn}
      >
        {modalcontent}
        <Fab className={classes.button} color='primary' onClick={() => this.onOpenModal('add_appointment_modal')}>
          <Icon >edit</Icon>
        </Fab>
        {contentView}
      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  onChangeView = async(id) => {
    this.setState({selected:id})
    this.setState({isloading:true})
    try{
      switch(id){
        case 2:
          await this.onGetVitalSigns()
          break;
        case 3:
          await this.onGetAppointments()
          break;
        case 5:
          await this.onGetImageData()
          break;
        case 6:
          await this.onGetLabData()
          break;
        default:
          break;
      }
    }catch(error){
      console.log(error)
    }

    this.setState({isloading:false})
  }

  onGetLabData = async() => {
    const {id} = this.props.match.params
    try{
      let response = await request_documents(id)
      this.setState({documents:[...response]})

    }catch(error){
      console.log(error)
    }
  }

  onGetImageData = async() => {
    const {id} = this.props.match.params
    try{
      let response = await request_images(id)
      this.setState({images:[...response]})

    }catch(error){
      console.log(error)
    }
  }

  ////////////////////////// VITAL SIGNS  ////////////////////////////////////////
  onGetVitalSigns = async() => {
    const {id} = this.props.match.params
    try{
      let response = await request_signal_vitals(id)
      response.reverse()
      this.setState({vitals_signs:[...response]})

    }catch(error){
      console.log(error)
    }
  }

  onAddVitalSigns = async(data) => {
    const {id} = this.props.match.params
    const type = 'add_vital_signs_modal'
    
    const data2send = {
      ...data,
      addition_date:moment().format('YYYY-MM-DD HH:mm:ss')
    }
    try{
      this.onEditModal(type,{issending:true})
      let response = await request_add_vital_signs(id, data2send)
      this.setState({vitals_signs:[...response]})
      this.onCloseModal(type)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  /////////////////////// APPOINTMENTS ///////////////////////////////

  

  ///////////////////////////////// PATIENT APPOINTMENTS  ///////////////////////////////////////////
  onAppointmentSelected = (appointment) => {
    const {id} = this.props.match.params
    this.props.history.push(`/patients/${id}/appointments/${appointment.id_appointment}`)
  }
  
  onGetAppointments = async() => {
    const {id} = this.props.match.params
    const doctor_id = JSON.parse(localStorage.getItem('data')).user_id
    try{
      let response = await request_doctor_appointments()
      const patient_appointments = response.filter(el => el.user_id.toString() === id)
      this.setState({appointments:[...patient_appointments]})

    }catch(error){
      console.log(error)
    }
  }

  onAddAppointment = async() => {
    const {id} = this.props.match.params
    const type = 'add_appointment_modal'
    const doctor_id = JSON.parse(localStorage.getItem('data')).user_id
    
    const data2send = {
      doctor_id:doctor_id,
      fixed_start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      fixed_end_date: moment().add(30,'minutes').format('YYYY-MM-DD HH:mm:ss'),
      appointment_type_id:1,
    }

    console.log(data2send)
    try{
      this.onEditModal(type,{issending:true})
      let response = await request_add_appointment(id, data2send)
      //const patient_appointments = response.filter(el => el.user_id.toString() === id)
      //this.setState({appointments:[...patient_appointments]})
      this.onCloseModal(type)
      this.props.history.push(`/patients/${id}/appointments/${response}`)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onDownloadDocument = async(image) => {

    const {id} = this.props.match.params
    console.log(image.id)

    try{
      await request_download_document(id, image.id)
    }catch(error){
      if(error.response){
        let e = {...error.response.data.error}
        //this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        //this.onEditModal(type,{error:`No Internet`})
      }

    }
  }


  //////////////////////////// PATIENT IMAGES FUNCTIONS //////////////////////////////////

  onPreUploadImage = (image) => {
    const data = {
      name:'Actualizar imagen',
      path:''
    }
    this.setState({selected_image:image})
    this.onOpenModal('upload_image_modal', data)
  }

  onDownloadImage = async(image) => {

    const {id} = this.props.match.params
    console.log(image.id)

    try{
      await request_download_image(id, image.id)
    }catch(error){
      if(error.response){
        let e = {...error.response.data.error}
        //this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        //this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onUploadImage = async(data, file) => {
    const {id} = this.props.match.params
    const type = 'upload_image_modal'
    const {selected_image} = this.state
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_upload_image(id, selected_image.id, data, file[0])
      this.setState({images:[...response]})
      this.onCloseModal(type)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onPreAddImage = () => {
    const data = {
      name:'Agregar nueva imagen',
      path:''
    }
    this.onOpenModal('add_image_modal',data)
  }

  onAddImage = async(data, file) => {
    const {id} = this.props.match.params
    const type = 'add_image_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_add_image(id, data, file[0])
      this.setState({images:[...response]})
      this.onCloseModal(type)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onEditImage = async(data) => {
    const {id} = this.props.match.params
    const {image_selected} = this.state

    const type = 'edit_image_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_image(id, image_selected.id, data)
      this.setState({images:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onDeleteImage = async() => {
    const {id} = this.props.match.params
    const {image_selected} = this.state

    const type = 'delete_image_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_delete_image(id, image_selected.id)
      this.setState({images:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onPreDeleteImage = (item) => {
    this.setState({image_selected:item})
    this.onOpenModal('delete_image_modal')
  }

  onImageSelected = (item) => {
    console.log(item)
    this.setState({image_selected:item})
    this.onOpenModal('edit_image_modal')
  }

  //////////////////////////// PATIENT LABS //////////////////////////////////
  onPreDeleteDocument = (item) => {
    this.setState({document_selected:item})
    this.onOpenModal('delete_document_modal')
  }

  onDocumentSelected = (item) => {
    console.log(item)
    this.setState({document_selected:item})
    this.onOpenModal('edit_document_modal')
  }

  onPreUploadDocument = (image) => {
    const data = {
      name:'Actualizar documento',
      path:''
    }
    this.setState({selected_document:image})
    this.onOpenModal('upload_document_modal', data)
  }

  onUploadDocument = async(data, file) => {
    const {id} = this.props.match.params
    const type = 'upload_document_modal'
    const {selected_document} = this.state
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_upload_document(id, selected_document.id, data, file[0])
      this.setState({images:[...response]})
      this.onCloseModal(type)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onPreAddDocument = () => {
    const data = {
      name:'Agregar nuevo estudio',
      path:''
    }
    this.onOpenModal('add_document_modal',data)
  }

  onDeleteDocument = async(data) => {
    const {id} = this.props.match.params
    const {document_selected} = this.state

    const type = 'delete_document_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_delete_document(id, document_selected.id)
      this.setState({documents:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onEditDocument = async(data) => {
    const {id} = this.props.match.params
    const {document_selected} = this.state

    const type = 'edit_document_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_edit_document(id, document_selected.id, data)
      this.setState({documents:[...response]})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  onAddDocument = async(data, file) => {
    const {id} = this.props.match.params
    const type = 'add_document_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = null
      response = await request_add_document(id, data, file[0])
      this.setState({documents:[...response]})
      this.onCloseModal(type)
    }catch(error){
      console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }

    }
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      let response = await request_patient(id)
      this.setState({user:JSON.parse(JSON.stringify(response))})
      response = await request_patient_history(id)
      this.setState({patient_history:{...response}})
      response = await request_infant_vaccination(id)
      this.setState({infant_vaccination:{...response}})
      response = await request_adult_vaccination(id)
      this.setState({adult_vaccination:{...response}})
      response = await request_signal_vitals(id)
      this.setState({vitals_signs:[...response]})

    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onReturn = () => {
    this.props.history.push('/patients')
  }

  onEdit = () => {
    this.props.history.push('/me/edit_profile')
  }

  onGo2AttendanceList = () => {

  }

  onEditProfile = () => {
    this.props.history.push('/me/edit')
  }

  onEditPatientHistory = () => {
    this.props.history.push('/me/edit-patient-history')
  }

  onUpdateVaccination = (form, data, nodata) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let date = nodata
      if(data){
        let temp_date = moment(data[item])
        if(temp_date.isValid()){
          date = temp_date.format('DD-MM-YYYY')
        }
      }
      temp[item].date = date
    })
    return temp
  }

  onUpdateFormData = (form, data, nodata, translations) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let value = nodata
      if(data){
        if(data[item]){
          if(temp[item].translate){
            value = translations[data[item]]
          }else{
            value = data[item]
          }
          
        }
      }
      temp[item].value = value
    })
    return temp
    
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
    
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(PatientView)
export const contentData = {
  spanish:{
    menu_form:[
      {id:0, label:'General'},
      {id:1, label:'Adicional'},
    ],
    form:{
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
        helper:'Nombre no válido'
      },
      fathers_last_name:{
        label:'Apellido Paterno',
        placeholder:'Apellido Paterno',
        helper:'Apellido Paterno no válido'
      },
      mothers_last_name:{
        label:'Apellido Materno',
        placeholder:'Apellido Materno',
        helper:'Apellido Materno no válido'
      },
      user_gender_id:{
        label:'Género',
        placeholder:'Género',
        helper:'Debe de seleccionar una opción'
      },
      email:{
        label:'Correo electrónico',
        placeholder:'Correo electrónico',
        helper:'Correo electrónico no válido'
      },
      mobile:{
        label:'Celular',
        placeholder:'Celular',
        helper:'Celular no válido'
      },
      blood_type_id:{
        label:'Tipo de sangre',
        placeholder:'Tipo de sangre',
        helper:'Debe de seleccionar una opción'
      },
      birth_date:{
        label:'Fecha de nacimiento',
        placeholder:'Fecha de nacimiento',
        helper:'Fecha de nacimiento no válida'
      },
      curp:{
        label:'CURP',
        placeholder:'CURP',
        helper:'CURP no válido'
      },
      fixed_phone:{
        label:'Teléfono fijo',
        placeholder:'Teléfono fijo',
        helper:'Teléfono fijo no válido'
      },
      timezone:{
        label:'Zona horaria',
        placeholder:'Zona horaria',
        helper:'Debe de seleccionar una opción'
      },
      occupation:{
        label:'Ocupación',
        placeholder:'Ocupación',
        helper:'Ocupación no válida'
      },
      religion:{
        label:'Religión',
        placeholder:'Religión',
        helper:'CURP no Religión'
      },
      details:{
        label:'Detalles',
        placeholder:'Detalles',
        helper:'Detalles no válido'
      },
    }
  }
  
}
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Editar información laboral',
      navigation:[
        {name:'Mi perfil',path:'/me'},
        {name:'Editar',path:'/me/edit-work'},
      ]
    },
    
  }
}
import {mainServer} from '../../../variables/config'
import axios from  'axios'
import moment from 'moment'
import FileSaver from 'file-saver';


export const request_patient_vital_signs = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vitalsign/${id}`)
  return response.data.data.user_vital_signs
}


export const request_add_vital_signs = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post(`/vitalsign/${id}`, data)
  let response
  response = await mainServer.instance.get(`/vitalsign/${id}`)
  return response.data.data.user_vital_signs
}

export const request_patient = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/users/${id}`)
  return response.data.data.user_found
}


export const request_doctor_appointments = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/appointment/doctor/me`)
  return response.data.data.doctor_appointment
}



export const request_add_appointment = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await  mainServer.instance.post(`/appointment/${id}`, data)
  console.log(response.data.data.appointment_id)
  const appointment_id = response.data.data.appointment_id

  const diagnosis_data = {
    issue_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    doctor_id: logData.user_id,
    appointment_id:appointment_id,
    language_id:1,
    diagnosis_1:'-'
  }

  await mainServer.instance.post(`/diagnosis/${id}`,diagnosis_data)

  const recipe_data = {
    doctor_id:logData.user_id,
    appointment_id:appointment_id,
    language_id:1,
  }

  await mainServer.instance.post(`/recipe/${id}`,recipe_data)

  return appointment_id
}



export const request_edit_image = async(id, document_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/patientimage/patientimage/${id}/${document_id}`,data);

  let response = await mainServer.instance.get(`/patientimage/${id}`)
  return response.data.data.patient_images
}

export const request_delete_image = async(id, document_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/patientimage/patientimage/${id}/${document_id}`)
  let response = await mainServer.instance.get(`/patientimage/${id}`)
  return response.data.data.patient_images
}

export const request_edit_document = async(id, document_id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/patientdocument/patientdocument/${id}/${document_id}`,data);

  let response = await mainServer.instance.get(`/patientdocument/${id}`)
  return response.data.data.patient_documents
}




export const request_delete_document = async(id, document_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/patientdocument/patientdocument/${id}/${document_id}`)
  let response = await mainServer.instance.get(`/patientdocument/${id}`)
  return response.data.data.patient_documents
}

export const request_add_document = async(id, data, file) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientdocument/${id}`,data);
  console.log(response)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/patientdocument/${id}`)
  return response.data.data.patient_documents
}


export const request_add_image = async(id, data, file) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientimage/${id}`,data);
  console.log(response)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/patientimage/${id}`)
  return response.data.data.patient_images
}


export const request_upload_image = async(id, image_id, data, file) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientimage/${id}/${image_id}/upload`,data);
  console.log(response)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/patientimage/${id}`)
  return response.data.data.patient_images
}

export const request_upload_document = async(id, image_id, data, file) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientdocument/${id}/${image_id}/upload`,data);
  console.log(response)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/patientdocument/${id}`)
  return response.data.data.patient_documents
}



export const request_download_image = async(patient_id, image_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  response = await mainServer.instance.get(`/patientimage/${patient_id}/${image_id}/download`)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, `image_${image_id}`);
}

export const request_download_document = async(patient_id, image_id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  response = await mainServer.instance.get(`/patientdocument/${patient_id}/${image_id}/download`)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, `document_${image_id}`);
} 

export const request_documents = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/patientdocument/${id}`)
  return response.data.data.patient_documents
}


export const request_images = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/patientimage/${id}`)
  return response.data.data.patient_images
}




export const request_signal_vitals = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vitalsign/${id}`)
  return response.data.data.user_vital_signs
}

export const request_patient_history = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/patienthistory/${id}`)
  return response.data.data.patient_history
}

export const request_infant_vaccination = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/infant`)
  return response.data.data.infant_vaccination_found
}

export const request_adult_vaccination = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/vaccination/${id}/adult`)
  return response.data.data.adult_vaccination_found
}

export const request_add_employee_record = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/employeerecord/me", data)
  let response
  response = await mainServer.instance.get("/employeerecord/me/list", 
  {params:{
    limit:20,
    offset:0,
    order:1,
    order_by:'timestamp'
  }})
  return response.data.data.employee_records
}
export const contentData = {
  spanish:{
    form:{
      email:{
        label:'Correo electrónico',
        placeholder:'Correo electrónico',
        helper:'Introduzca un E-mail válido'
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
        helper:'Introduzca una contraseña válida'
      }
    },
    error:'Usuario/Contraseña incorrectos',
    button:{label:'Ingresar'}
  }
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import ContentText from '../../Texts/ContentText';
import { Grid, Icon } from '@material-ui/core';
import PopperWrapper from '../../Poppers/PopperWrapper';

import { Scrollbars } from 'react-custom-scrollbars';

class TableMenu extends Component {

  state = {
    open:false,
    anchorEl:null
  }

  render(){

    const {classes, selected, options} = this.props
    const {anchorEl, open} = this.state

    let option = options.find(el => el.id === selected)
    let mainLabel = option.label

    return(
      <div>
        <PopperWrapper
          open={open}
          anchorEl={anchorEl}
          onClickAway={this.onCloseMenu}
        >
          <div className={classes.popper}>
            <Scrollbars
              style={{
                width:'200px',
                height:'200px'
              }}
              
              renderTrackHorizontal={props => <div {...props} style={{display: 'none', overflow:'hidden'}} className="track-horizontal"/>}
              renderView={props => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden', paddingLeft:4, paddingRight:4 }} />
              )}
            >
              <Grid container direction='column'>
                {options.map((item,key)=>{

                  const isSelected = selected === item.id

                  return(
                    <Grid item key={key.toString()}>
                      <div className={classes.menuItem} onClick={() => this.onItemSelected(item.id)}>
                        <ContentText text={item.label} medium={isSelected} variant={isSelected ? 'bold' : null}/>
                      </div>
                      
                    </Grid>
                  )
                  
                })}
              </Grid>
              <div style={{height:20}}/>
            </Scrollbars>
          </div>
        </PopperWrapper>

        <div className={classes.button} onClick={this.onOpenMenu}>
          <Grid container alignItems='center' spacing={0}>
            <Grid item>
              <Icon style={{paddingTop:4}}>arrow_drop_down</Icon>
            </Grid>
            <Grid item>
              <ContentText text={mainLabel} medium variant='bold'/>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }

  onItemSelected = (id) => {
    
    const {onItemSelected, selected, options} = this.props
    if(selected !== id){
      let option = options.find(el => el.id === id)
      onItemSelected(option)
    }
    this.onCloseMenu()
  }

  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = (event) => {
    const { currentTarget } = event;
    this.setState({anchorEl: currentTarget,open: true});
  }
}

const styles = theme => ({
  root:{},
  button:{
    '&:hover':{
      cursor:'pointer'
    }
  },
  menuItem:{
    padding:16,
    '&:hover':{
      background:'rgba(0,0,0,0.05)',
      cursor:'pointer'
    }
  },
  popper:{
  }
})

export default withStyles(styles)(TableMenu)



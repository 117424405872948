import React, {Component} from  'react'
import { withStyles, Table, TableHead, TableRow, TableCell, IconButton, Icon, TableBody } from '@material-ui/core';
import ContentText from '../Texts/ContentText';
import { palette } from '../../variables/config';
import moment from 'moment'

const header = [
  'Medicamento','Dosis','Unidad','Frecuencia','Duración','Vía de administración','Fecha de inicio','Fecha de fin']


const fakedata = [
  {
    "non_registered_medication":"Test", 
    "dosis":100, 
    "unit_of_measurement":"Test", 
    "frequency":"Test",
    "duration":"Test",
    "administration_route_id":1, 
    "start_date":"2019-07-02 08:00:00",
    "end_date":"2019-08-010 09:00:00"
  }
]  

class MedicationTable extends Component{
  render(){

    const {classes, onAdd, onEdit, onDelete, medications} = this.props

    return(
      <div>
        <Table>
          <TableHead>
            <TableRow >
            {header.map((item,key)=>{
              
              return(
                  <TableCell className={classes.cell_head} key={key.toString()}>
                    <ContentText text={item} medium color={palette.primary.main}/>
                  </TableCell>
              )
            })}
              <TableCell className={classes.cell_head}>
                <IconButton size='small' className={classes.add_button} onClick={onAdd}>
                  <Icon fontSize='small'>add</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medications.map((item,key)=>{
              return(
                <TableRow key={key.toString()}>
                  {Object.keys(item).map((el,id)=>{
                    if(el !== 'id_recipe_medication'){
                      let value = item[el]
                      if(el === 'start_date' || el === 'end_date'){
                        value = moment(value).format('DD/MM/YYYY')
                      }
  
                      return(
                        <TableCell key={id.toString()} className={classes.cell_body}>
                          <ContentText text={value} medium/>
                        </TableCell>
                      )
                    }else{
                      return null
                    }
                    
                  })}
                  <TableCell className={classes.cell_body}>
                    <IconButton size='small' className={classes.edit_button} onClick={()=>onEdit(item)}>
                      <Icon fontSize='small'>edit</Icon>
                    </IconButton>
                    <IconButton size='small' className={classes.delete_button} onClick={()=>onDelete(item)}>
                      <Icon fontSize='small'>delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const styles = theme => ({
  root:{},
  cell_head:{
    border:0,
  },
  cell_body:{
    border:0,
  },
  add_button:{
    background:theme.palette.primary.main,
    color:'white',
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  }
})

export default withStyles(styles)(MedicationTable)

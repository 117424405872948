import Dashboard from "../views/Dashboard/Dashboard/Dashboard";
import EditProfileCard from "../views/Dashboard/EditProfileCard/EditProfileCard";
import Patients from "../views/Patients/Patients/Patients";
import PatientView from "../views/Patients/PatientView/PatientView";
import EditDoctorWork from "../views/Dashboard/EditDoctorWork/EditDoctorWork";
import EditDoctorSchedule from "../views/Dashboard/EditDoctorSchedule/EditDoctorSchedule";
import Appointments from "../views/Appointments/Appointments/Appointments";
import AppointmentView from "../views/Appointments/AppointmentView/AppointmentView";
import AddPatient from "../views/Patients/AddPatient/AddPatient";
import EditPatient from "../views/Patients/EditPatient/EditPatient";
import EditPatientHistory from "../views/Patients/EditPatientHistory/EditPatientHistory";
import InfantVaccinationView from "../views/Patients/Vaccinations/InfantVaccinationView/InfantVaccinationView";
import AdultVaccinationView from "../views/Patients/Vaccinations/AdultVaccinationView/AdultVaccinationView";


const dashboard = {
  sections:[
    {
      path: "/me",
      icon:'person',
      label:'Mis datos',
      component: Dashboard,
      sidebar:true,
      exact:true,
      level:2
    },
    {
      path: "/me/edit",
      label:'Mis datos',
      component: EditProfileCard,
      exact:true,
      level:1
    },
    {
      path: "/me/edit-work",
      label:'Mis datos',
      component: EditDoctorWork,
      exact:true,
      level:1
    },
    {
      path: "/me/edit-schedule",
      label:'Mis datos',
      component: EditDoctorSchedule,
      exact:true,
      level:1
    },
    
    {
      path: "/patients",
      icon:'group',
      label:'Pacientes',
      component: Patients,
      sidebar:true,
      exact:true,
      level:1
    },
    {
      path: "/patients/add",
      label:'Usuarios',
      component: AddPatient,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id",
      label:'Usuarios',
      component: PatientView,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id/edit",
      component: EditPatient,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id/edit-patient-history",
      component: EditPatientHistory,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id/vaccination/infant",
      component: InfantVaccinationView,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id/vaccination/adult",
      component: AdultVaccinationView,
      exact:true,
      level:1
    },
    {
      path: "/patients/:id/appointments/:appointment_id",
      component: AppointmentView,
      sidebar:false,
      exact:true,
      level:1
    },
    {
      path: "/appointments",
      icon:'access_time',
      label:'Citas',
      component: Appointments,
      sidebar:true,
      exact:true,
      level:1
    },
    {
      path: "/appointments/:id",
      icon:'access_time',
      label:'Citas',
      component: AppointmentView,
      sidebar:false,
      exact:true,
      level:1
    },
    { redirect: true, path: "/", to: "/me", navbarName: "Redirect" }
  ]
}

export default dashboard
export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Vacunación infantil',
      navigation:[
        {name:'Pacientes',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Vacunación Infantil',path:'/patients/:id/vaccination/infant'},
      ]
    },
    vacinnation_modal:{
      title:'Editar vacuna',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    },
    infant_vacinnation:{
      header:[
        {text:'Vacuna'},
        {text:'Enfermedad'},
        {text:'Dósis'},
        {text:'Frecuencia'},
        {text:'Fecha'},
      ],
      body:[
        {
          vaccine:'BCG',
          illness:'Primera',
          data:[
            {dose:'Única', frequency:'Al nacer', data:null, id:'bcg'},
          ]
        },
        {
          vaccine:'Hepatitis B',
          illness:'Hepatitis B',
          data:[
            {dose:'Primera', frequency:'Al nacer', data:null, id:'hepatitis_b_1'},
            {dose:'Segunda', frequency:'2 meses', data:null, id:'hepatitis_b_2'},
            {dose:'Tercera', frequency:'6 meses', data:null, id:'hepatitis_b_3'},
          ]
        },
        {
          vaccine:'Pentavalente',
          illness:'Difteria, Tos Ferina, Tétanos, Poliomielitis e Infecciones',
          data:[
            {dose:'Primera', frequency:'2 meses', data:null, id:'pentavalente_1'},
            {dose:'Segunda', frequency:'4 meses', data:null, id:'pentavalente_2'},
            {dose:'Tercera', frequency:'6 meses', data:null, id:'pentavalente_3'},
            {dose:'Cuarta', frequency:'18 meses', data:null, id:'pentavalente_4'},
          ]
        },
        {
          vaccine:'DPT',
          illness:'Difteria, Tos Ferina y Tétanos',
          data:[
            {dose:'Refuerzo', frequency:'4 años', data:null, id:'dpt'},
          ]
        },
        {
          vaccine:'Rotavirus',
          illness:'Diarrea por rotavirus',
          data:[
            {dose:'Primera', frequency:'2 meses', data:null, id:'rotavirus_1'},
            {dose:'Segunda', frequency:'4 meses', data:null, id:'rotavirus_2'},
          ]
        },
      ]
    }
  }
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import { Grid } from '@material-ui/core';
import ProfileCard from './components/ProfileCard';
import { request_me,  request_doctor_details, request_doctor_translations, request_doctor_addresss, request_doctor_slots, request_doctor_next_appointment } from './request';
import moment from 'moment'
import WorkInfoCard from './components/WorkInfoCard';
import ScheduleCard from './components/ScheduleCard';



class Dashboard extends Component {

  state = {
    isloading:false,
    user:null,
    doctor_slots:[],
    doctor_details:null,
    doctor_translations:null,
    address:null,
    doctor_appointment:null,
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }
  
  render(){

    const {classes,language, translations} = this.props
    const {user, isloading, doctor_details, doctor_translations, address, doctor_slots, doctor_appointment} = this.state
    const content = contentData[language]
    console.log(JSON.parse(localStorage.getItem('data')))

    let profile_content = this.onUpdateFormData(content.profile_card.form, user, content.nodata, translations)

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
      >
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <ProfileCard 
                    language={language}
                    user={user} 
                    doctor_translations={doctor_translations}
                    form={profile_content} 
                    onEdit={this.onEditProfile}/>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <WorkInfoCard 
                    data={doctor_details} 
                    translations={translations} 
                    address={address}
                    onEdit={this.onEditWorkInfo}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ScheduleCard disabled onEdit={this.onEditSchedule} doctor_slots={doctor_slots}/>
            </Grid>
          </Grid>
        </div>
      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try {
      let response = await request_me()
      this.setState({user:JSON.parse(JSON.stringify(response))})
      response = await request_doctor_details()
      this.setState({doctor_details:{...response}})
      
      response = await request_doctor_addresss()
      this.setState({address:{...response}}) //
      response = await request_doctor_slots()
      this.setState({doctor_slots:[...response]})
      response = await request_doctor_next_appointment()
      this.setState({doctor_appointment:{...response}})
      response = await request_doctor_translations()
      this.setState({doctor_translations:{...response}}) //request_doctor_addresss
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  

  onEdit = () => {
    this.props.history.push('/me/edit_profile')
  }

  onEditWorkInfo = () => {
    this.props.history.push('/me/edit-work')
  }

  onEditSchedule = () => {
    this.props.history.push('/me/edit-schedule')
  }

  onGo2Meetting = () => {
    const {doctor_appointment} = this.state
    console.log(doctor_appointment.room_id)
  }

  onEditProfile = () => {
    this.props.history.push('/me/edit')
  }

  onEditPatientHistory = () => {
    this.props.history.push('/me/edit-patient-history')
  }

  onUpdateVaccination = (form, data, nodata) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let date = nodata
      if(data){
        let temp_date = moment(data[item])
        if(temp_date.isValid()){
          date = temp_date.format('DD-MM-YYYY')
        }
      }
      temp[item].date = date
    })
    return temp
  }

  onUpdateFormData = (form, data, nodata, translations) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let value = nodata
      if(data){
        if(data[item]){
          if(temp[item].translate){
            value = translations[data[item]]
          }else{
            value = data[item]
          }
          
        }
      }
      temp[item].value = value
    })
    return temp
    
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
    
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(Dashboard)
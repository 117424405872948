import React, { Component } from 'react';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid } from '@material-ui/core';
import VerticalDataWrapper from '../../../../components/Texts/VerticalDataWrapper';

class WorkInfoCard extends Component {
  render(){

    const {classes, data, translations, address, onEdit} = this.props

    let data_form = this.onUpdateForm(content.form, data, translations)

    let address_data = this.onGetAddress(address)

    return(
      <div>
        <CardWrapper
          title='Información laboral'
          link='Editar'
          onClickLink={onEdit}>
            <div className={classes.root}>
              <Grid container spacing={2}>
                {Object.keys(data_form).map((item,key)=>{
                  return(
                    <Grid item xs={12} md={6} key={key.toString()}>
                      <VerticalDataWrapper 
                        label={data_form[item].label} 
                        text={data_form[item].value}/>
                    </Grid>
                  )
                })}
                <Grid item xs={12}/>
                <Grid item xs={12}/>
                <Grid item xs={12}>
                  <VerticalDataWrapper 
                    label='Dirección'
                    text={address_data}
                  />
                </Grid>
              </Grid>
            </div>
        </CardWrapper>
      </div>
    )
  }

  onGetAddress = (address) => {
    let temp = ''
    if(address){
      const {street, ext, int, suburb, city, state, zip} = address
      temp += street ? street : ''
      temp += ext ? ` #${ext}`  : ''
      temp += int ? ` ${int}` : ''
      temp += suburb ? `, ${suburb}` : ''
      temp += city ? `, ${city}` : ''
      temp += state ? `, ${state}` : ''
      temp += zip ? `, ${zip}` : ''
      temp += '.'
    }
    return temp
  }

  onUpdateForm = (form,data, translations) => {
    let temp = {...form}
    Object.keys(form).forEach(item => {
      if(data){
        if(temp[item].translate){
          temp[item].value = data[item] ? translations[data[item]] : '-' 
        }else{
          temp[item].value = data[item] ? data[item] : '-' 
        }
        
      }else{
        temp[item].value = '-' 
      }
     
    })
    return temp
  }
}

const styles = theme => ({
  root:{
    padding:24
  }
})

export default withStyles(styles)(WorkInfoCard)

const content = {
  form:{
    speciality_text_id:{
      label:'Especialidad',
      translate:true,
    },
    sub_speciality_text_id:{
      label:'Subespecialidad',
      translate:true,
    },
    license:{
      label:'Cédula Profesional'
    },
    ssa:{
      label:'SSA'
    }
  }
}
export const contentData = {
  spanish:{
    menu_form:[
      {id:0, label:'General'},
      {id:1, label:'Dirección'},
    ],
    form:{
      speciality_id:{
        label:'Especialidad',
        placeholder:'Especialidad',
        helper:'Debe de seleccionar una opción'
      },
      sub_speciality_id:{
        label:'Subespecialidad',
        placeholder:'Subespecialidad',
        helper:'Debe de seleccionar una opción'
      },
      license:{
        label:'Cédula profesional',
        placeholder:'Cédula profesional',
        helper:'Información no válida'
      },
      ssa:{
        label:'SSA',
        placeholder:'SSA',
        helper:'Información no válida'
      },
      street:{
        label:'Calle',
        placeholder:'Calle',
        helper:'Información no válida'
      },
      ext:{
        label:'Ext',
        placeholder:'SSA',
        helper:'Información no válida'
      },
      int:{
        label:'Int',
        placeholder:'SSA',
        helper:'Información no válida'
      },
      suburb:{
        label:'Colonia',
        placeholder:'Colonia',
        helper:'Información no válida'
      },
      city:{
        label:'Ciudad',
        placeholder:'Ciudad',
        helper:'Información no válida'
      },
      state:{
        label:'Estado',
        placeholder:'Estado',
        helper:'Información no válida'
      },
      zip:{
        label:'C.P.',
        placeholder:'C.P.',
        helper:'Información no válida'
      },
      
      
    }
  }


  
}
import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid } from '@material-ui/core';
import LongTextData from '../../../../../components/DisplayData/LongTextData';
import ContentText from '../../../../../components/Texts/ContentText';
import VerticalDataWrapper from '../../../../../components/Texts/VerticalDataWrapper';

class PatientHistoryCard extends Component {
  render(){

    const {classes, language, patient_history, translations, onEdit} = this.props
    const content = contentData[language]
    const formData = this.onUpdateFormData(content.form, patient_history, content.nodata, translations)

    return(
      <div>
        <CardWrapper
          title='Mi historia médica'
          link='Editar'
          onClickLink={onEdit}
        >
          <div className={classes.cardContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LongTextData 
                  label={content.current_condition_title} 
                  text={patient_history ? patient_history.current_conditions : '-'}/>
              </Grid>
              <Grid item xs={12}>
                <LongTextData 
                  label={content.pathological_history_title} 
                  text={patient_history ? patient_history.pathological_history : '-'}/>
              </Grid>
              <Grid item xs={12}>
                <LongTextData 
                  label={content.family_history_title} 
                  text={patient_history ? patient_history.family_history : '-'}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContentText text={content.no_pathological_history} medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {Object.keys(formData).map((item,key)=>{
                        return(
                          <Grid item xs={12} md={4} key={key.toString()}>
                            <VerticalDataWrapper 
                              label={formData[item].label} 
                              text={formData[item].value}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          
        </CardWrapper>
      </div>
    )
  }

  onUpdateFormData = (form, data, nodata, translations) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let value = nodata
      if(data){
        if(data[item]){
          if(temp[item].translate){
            value = translations[data[item]] ? translations[data[item]] : nodata 
          }else{
            value = data[item]
          }
        }
      }
      temp[item].value = value
    })
    return temp
    
  }
}

const contentData = {
  spanish:{
    current_condition_title:'Condiciones actuales',
    pathological_history_title:'Antedecentes personales patológicos',
    family_history_title:'Antecedentes heredofamiliares',
    no_pathological_history:'Antedecentes personales no patológicos',
    nodata:'-',
    form:{
      alcohol_condition_text_id:{
        label:'Alcohol',
        translate:true
      },
      family_history:{
        label:'Tabaco',
      },
      home_type_text_id:{
        label:'Vivienda',
        translate:true
      },
      home_services:{
        label:'Servicios',
      },
      home_pets:{
        label:'Faunas',
      },
      inmunization_type_text_id:{
        label:'Inmunizaciones',
        translate:true
      },
    }
  }
}

const styles = theme => ({
  root:{},
  cardContainer:{
    padding:24,
    [theme.breakpoints.down('sm')]: {
      padding:16,
    },
  }
})

export default withStyles(styles)(PatientHistoryCard)
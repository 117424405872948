import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {contentData} from './content'
import { Grid, Icon, Fab } from '@material-ui/core';
import ProfileCard from './components/ProfileCard';
import { request_patient, request_add_employee_record, request_patient_history, request_infant_vaccination, request_adult_vaccination } from './request';
import PatientHistoryCard from './components/PatientHistoryCard';
import moment from 'moment'
import VaccinationCard from './components/VaccinationCard';
import VitalSignCard from './components/VitalSignCard';



class PatientView extends Component {

  state = {
    isloading:false,
    user:null,
    patient_history:null,
    adult_vaccination:null,
    infant_vaccination:null,
    employee_records:[],
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }
  
  render(){

    const {classes,language, catalogs, translations, patient_id} = this.props
    const {user, patient_history, vitals_signs, infant_vaccination, adult_vaccination} = this.props
    const content = contentData[language]

    //console.log(translations)

    let profile_content = this.onUpdateFormData(content.profile_card.form, user, content.nodata, translations)
    let infant_vaccination_content = this.onUpdateVaccination(content.infant_vaccination, infant_vaccination, content.novaccination)
    let adult_vaccination_content = this.onUpdateVaccination(content.adult_vaccination, adult_vaccination, content.novaccination)
    let vital_signs_data = this.onUpdateVitalSigns(content.vital_signs, vitals_signs)

    let sign_vital_content = null

    if(content.vital_signs){
      sign_vital_content = Object.keys(vital_signs_data).map((item,key)=>{
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            <VitalSignCard data={vital_signs_data[item]} />
          </Grid>
        )
      })
    }

    return(
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <ProfileCard 
                    language={language}
                    user={user} 
                    form={profile_content} 
                    onEdit={this.onEditProfile}/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {sign_vital_content}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PatientHistoryCard 
                    translations={translations}
                    patient_history={patient_history}
                    language={language}
                    onEdit={this.onEditPatientHistory}/>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <VaccinationCard 
                    onClick={() => this.props.history.push(`/patients/${patient_id}/vaccination/infant`)}
                    title='Vacunación infantil'
                    language={language} 
                    data={infant_vaccination_content}/>
                </Grid>
                <Grid item xs={12}>
                  <VaccinationCard 
                    onClick={() => this.props.history.push(`/patients/${patient_id}/vaccination/adult`)}
                    title='Vacunación adulto'
                    language={language} 
                    data={adult_vaccination_content}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {

  }

  onReturn = () => {
    this.props.history.push('/patients')
  }

  onEdit = () => {
    this.props.history.push('/me/edit_profile')
  }

  onGo2AttendanceList = () => {

  }

  onEditProfile = () => {
    const {patient_id} = this.props
    this.props.history.push(`/patients/${patient_id}/edit`)
  }

  onEditPatientHistory = () => {
    const {patient_id} = this.props
    this.props.history.push(`/patients/${patient_id}/edit-patient-history`)
  }

  onUpdateVitalSigns = (form, data) => {
    let temp = JSON.parse(JSON.stringify(form))
    data.reverse()
    if(data){
      data.forEach((vital_sign)=>{
        Object.keys(vital_sign).forEach(el => {
          
          if(el !== 'id_vital_sign' && el !== 'patient_id' && el !== 'patient_name' && el !== 'addition_date'){
            if(temp[el]){
              if(temp[el].value === null || temp[el].value === undefined){
                temp[el].value = vital_sign[el]
                temp[el].timestamp = vital_sign.addition_date
              }
            }
          }
        })
      })
    }
    return temp
  }

  onUpdateVaccination = (form, data, nodata) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let date = nodata
      if(data){
        let temp_date = moment(data[item])
        if(temp_date.isValid()){
          date = temp_date.format('DD-MM-YYYY')
        }
      }
      temp[item].date = date
    })
    return temp
  }

  onUpdateFormData = (form, data, nodata, translations) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let value = nodata
      if(data){
        if(data[item]){
          if(temp[item].translate){
            value = translations[data[item]]
          }else{
            value = data[item]
          }
          
        }
      }
      temp[item].value = value
    })
    return temp
    
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
    
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(PatientView)
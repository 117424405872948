import React, {Component} from 'react'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles, Grid, fade } from '@material-ui/core';
import LongTextData from '../../../../components/DisplayData/LongTextData';
import ContentText from '../../../../components/Texts/ContentText';

class DiagnosisCard extends Component {
  render(){

    const {classes, diagnosis, onEdit} = this.props

    return(
      <CardWrapper
        title='Exploración física'
      >
        <div className={classes.root}>
          <div className={classes.container} >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='head_exploration'
                  label='Cabeza y cuello'
                  text={diagnosis ? diagnosis.head_exploration : '-'}
                  onEdit={() => onEdit('head_exploration')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='torax_exploration'
                  label='Torax'
                  text={diagnosis ? diagnosis.torax_exploration : '-'}
                  onEdit={() => onEdit('torax_exploration')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='abdomen_exploration'
                  label='Abdomen'
                  text={diagnosis ? diagnosis.abdomen_exploration : '-'}
                  onEdit={() => onEdit('abdomen_exploration')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='genitalia_exploration'
                  label='Genitales'
                  text={diagnosis ? diagnosis.genitalia_exploration : '-'}
                  onEdit={() => onEdit('genitalia_exploration')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='superior_limbs_exploration'
                  label='Miembros superiores'
                  text={diagnosis ? diagnosis.inferior_limbs_exploration : '-'}
                  onEdit={() => onEdit('superior_limbs_exploration')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LongTextData 
                  id='inferior_limbs_exploration'
                  label='Miembros inferiores'
                  text={diagnosis ? diagnosis.superior_limbs_exploration : '-'}
                  onEdit={() => onEdit('inferior_limbs_exploration')}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </CardWrapper>
    )
  }

  onEditData = () => {
  }
}

const styles = theme => ({
  root:{padding:16},
  container:{
    //background: fade(theme.palette.primary.main,0.2),
    padding:8,
    borderRadius:8,
    minHeight:260
  }
})

export default withStyles(styles)(DiagnosisCard)
import React, { Component } from 'react';
import MasterTableA from '../../../../components/Tables/MasterTableA';
import { contentData } from './content';
import moment from 'moment'

class PatientAppointments extends Component {

  state = {
    isloading:false,
    appointments:[],
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
  }

  render(){

    const {language, appointments, translations} = this.props
    const {table_config} = this.state

    const content = contentData[language]

    //console.log(appointments)
    let current_appointments = this.onUpdateData(appointments, translations)
    console.log(current_appointments)
    return(
      <div>
        <MasterTableA 
          data={current_appointments}
          table={content.table} 
          config={table_config}
          onSelectedRow={this.onSelectedRow}
          onSearch={this.onSearch}
          onChangePageNumber={this.onChangePageNumber}
          onChangePage={this.onChangePage}/>
        
      </div>
    )
  }

  onUpdateData = (data, translations) => {
    let temp = [...data]
    data.forEach((item,key)=>{
      const appointment_type_text_id = item.appointment_type_text_id
      temp[key] = {
        ...item, 
        timestamp:moment(item.start_date).format('DD/MM/YYYY HH:mm') + 'hrs',
        appointment_type: appointment_type_text_id ? translations[appointment_type_text_id] : '-' 
      }
    })
    return temp
  }

  onSelectedRow = (item) => {
    //const {id} = this.props.match.params
    this.props.onAppointmentSelected(item)
    //this.props.history.push(`/patients/${id}/appointments/${item.id_appointment}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    const {appointments} = this.props
    this.setState({appointments:[...appointments]})
  }

}

export default PatientAppointments
export const contentData = {
  spanish:{
    form:{
      date:{
        label:'Seleccione una fecha',
        placeholder:'Fecha',
        helper:'Fecha no válida'
      },
    },
    
  }
}